<div class="banner_image">
    <app-header (gotoCart)="switchTocartView()">

    </app-header>
    <app-login *ngIf="!userData">

    </app-login>
    <div class="alreadyLoggedIn" *ngIf="userData">
      Welkom, je bent ingelogd als: {{userData?.Name}}
    </div>
    <app-home [cartSwitch]="userData"></app-home>
</div>
<app-footer></app-footer>

import {Component, Input, OnChanges, OnInit, SimpleChange} from '@angular/core';
import {UserService} from "../../services/user.service";
import { RouterOutlet } from '@angular/router';
import {slideLeftRight} from "../../animation";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [slideLeftRight]
})
export class HomeComponent implements OnInit, OnChanges {
  tabSwitchedDone = false;
  userData: any;
  activeTabIndex = 0;
  showLoginMessage: boolean = false;
  @Input() cartSwitch!: boolean;
  options:any[]=[  ]

  constructor(public userService: UserService) { }

  ngOnChanges(changes: any): void {
    // if(changes.cartSwitch) {
    //   this.userService.activeTabIndex = 3;
    // }
  }
  ngOnInit(): void {
    // console.log(this.cartSwitch);
    this.userService.userDataObservable.subscribe((user: any) => {
      this.userData = user;
      this.showLoginMessage = false;
      this.userService.activeTabIndex = 0;
    });
    if (this.userService.status){
      this.options = [
        {
          name: 'Home',
          image: './assets/imgs/home_icon.png',
          routerLink: 'home'
        },
        {
          name: 'Banden',
          image: './assets/imgs/banden_icon.png',
          routerLink: 'banden'
        },
        {
          name: 'Velgen',
          image: './assets/imgs/velgen_icon.png',
          routerLink: 'velgen'
        },
        {
          name: 'Account',
          image: './assets/imgs/account_icon.png',
          routerLink: 'account'
        }
      ];
    }
    else {
      this.options = [
        {
          name: 'Home',
          image: './assets/imgs/home_icon.png',
          routerLink: 'home'
        },
        {
          name: 'Banden',
          image: './assets/imgs/banden_icon.png',
          routerLink: 'home'
        },
        {
          name: 'Velgen',
          image: './assets/imgs/velgen_icon.png',
          routerLink: 'home'
        },
        {
          name: 'Account',
          image: './assets/imgs/account_icon.png',
          routerLink: 'home'
        }
      ];

    }
    this.userService.getTotalCount();
  }
  tabSwiched() {
    if(!this.userData) {
      if (this.userService.activeTabIndex > 0) {
        this.showLoginMessage = true;
        this.userService.activeTabIndex = 0;
      }
    } else {
      this.showLoginMessage = false;
    }
    this.tabSwitchedDone = !this.tabSwitchedDone;
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

}

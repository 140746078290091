<div class="warning" *ngIf="isMessageVisible">
  Om dit deel van de website te kunnen bekijken, moet u ingelogd zijn.
</div>


<div class="home_tab_content">

  <div [innerHTML]="contentService.homeContent.data['content-top'][0].content"
       *ngIf="contentService?.homeContent && contentService.homeContent.data['content-top'][0] &&
       contentService.homeContent.data['content-top'][0].content" class="upperBox bg_blue white text_center border_radious10">

<!--    <h5>-->
<!--      Over ABC Autobandencentrum-->
<!--    </h5>-->
<!--    <p>-->
<!--      Dit is de webshop van ABC Auto Banden Centrum voor de business to business markt. Via deze weg kunt u nu nog gemakkelijker banden, velgen en-->
<!--      toebehoren bestellen.-->
<!--    </p>-->
<!--    <p>-->
<!--      Heeft u nog geen inlogcode voor de webshop, maar heeft u wel interesse? Neem dan contact met ons op via de onderstaande nummers.-->
<!--    </p>-->
<!--    <p>-->
<!--      Voor vragen, opmerkingen of advies kunt u altijd terecht bij onze medewerkers via telefoonnummer 0318-616263 (Ede) en 0342-421111 (Barneveld).-->
<!--    </p>-->
  </div>
  <div class="twoBoxes">
    <div [innerHTML]="contentService.homeContent.data['content-middle-left'][0].content"
         *ngIf="contentService?.homeContent?.data && contentService.homeContent.data['content-middle-left'][0] &&
       contentService.homeContent.data['content-middle-left'][0].content" class="leftBox border_radious10 box_shadow bg_white">
<!--      <div>-->
<!--        <h5>-->
<!--          Wie zijn wij-->
<!--        </h5>-->
<!--        <p class="grey">-->
<!--          ABC Autobandencentrum is al ruim 30 jaar de specialist op de Veluwe als het gaat om autobanden,-->
<!--          lichtmetalen velgen, balanceren en uitlijnen. Door de groei die wij de laatste jaren hebben doorgemaakt,-->
<!--          voorzien wij met onze centraal gelegen vestigingen, in de vraag naar deze producten.-->
<!--        </p>-->

<!--        <h5>Producten</h5>-->
<!--        <p class="grey">-->
<!--          Naast een uitgebreid gamma aan autobanden, van diverse huismerken tot A- merken, hebben wij ook een breed assortiment aan lichtmetalen velgen op-->
<!--          voorraad. Verder leveren wij ook accu’s voor ieder gewenste auto en alles voor de montage van de banden, zoals ventielen, balanceergewichten, bandenvet etc. Dit-->
<!--          alles uit voorraad.-->
<!--        </p>-->
<!--        <h5>-->
<!--          Distributie-->
<!--        </h5>-->
<!--        <p class="grey">-->
<!--          Vanuit onze vestigingen bieden wij minimaal 4 keer per dag een route, hierdoor is-->
<!--          voor u ook vlotte afhandeling van bandenvervanging voor uw klant mogelijk.-->
<!--        </p>-->
<!--      </div>-->
    </div>
    <div [innerHTML]="contentService.homeContent.data['content-middle-right'][0].content"
         *ngIf="contentService?.homeContent?.data && contentService.homeContent.data['content-middle-right'][0] &&
       contentService.homeContent.data['content-middle-right'][0].content"
         class="rightBox w40 bg_white border_radious10 box_shadow white">
<!--      <h5>-->
<!--        Wat kan ABC voor u betekenen-->
<!--      </h5>-->
<!--        <ul>-->
<!--          <li>Ruime voorraad;</li>-->
<!--          <li>Concurrerende prijzen;</li>-->
<!--          <li>Snelle levering;</li>-->
<!--          <li>Vakkundig advies;</li>-->
<!--          <li> Directe oplossing bij eventuele garantie;</li>-->
<!--          <li>Direct terecht kunnen voor uitlijnen of balanceren;</li>-->
<!--          <li>Indien de door u gewenste band niet bij ons of bij vaste-->
<!--            leverancier op voorraad is gaan wij voor u opzoek.</li>-->
<!--        </ul>-->
    </div>
  </div>
</div>
<app-product-box>

</app-product-box>

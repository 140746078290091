<div class="footer bg_blue">
  <div class="wrapper">
    <div class="footer_child">
      <p>
        <a href="#" class="white light_font">Algemene voorwaarden</a>
      </p>
      <p>
        <a href="#" class="white light_font">Ontwerp en realisatie door Storm Media</a>
      </p>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeTabComponent} from "./Components/home-tab/home-tab.component";
import {WheelTabComponent} from "./Components/wheel-tab/wheel-tab.component";
import {RimTabComponent} from "./Components/rim-tab/rim-tab.component";
import {AccountTabComponent} from "./Components/account-tab/account-tab.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeTabComponent,
    data: {animation: 'HomePage'},
  },
  {
    path: 'banden',
    component: WheelTabComponent,
    data: {animation: 'BandenPage'},
  },
  {
    path: 'velgen',
    component: RimTabComponent,
    data: {animation: 'VelgenPage'},
  },
  {
    path: 'account',
    component: AccountTabComponent,
    data: {animation: 'AccountPage'},
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<!--<section class="rim_tab">-->
<!--    <div class="rim_tab_child">-->
<!--        <div class="left_good_year_detail_div bg_white border_radious10 box_shadow">-->
<!--            <h5 class="blue">Good-Year</h5>-->
<!--            <div class="detail_table">-->
<!--                <table>-->
<!--                    <tbody>-->
<!--                        <tr>-->
<!--                            <td class="title grey bold_font">Type</td>-->
<!--                            <td class="grey">155/65R14 155/65R14 GOODYEAR EFF GRCOM</td>-->
<!--                        </tr>-->
<!--                        <tr>-->
<!--                            <td class="title grey bold_font">Breedte</td>-->
<!--                            <td class="grey">155</td>-->
<!--                        </tr>-->
<!--                        <tr>-->
<!--                            <td class="title grey bold_font">Hoogte</td>-->
<!--                            <td class="grey">65</td>-->
<!--                        </tr>-->
<!--                        <tr>-->
<!--                            <td class="title grey bold_font">Diameter</td>-->
<!--                            <td class="grey">14</td>-->
<!--                        </tr>-->
<!--                        <tr>-->
<!--                            <td class="title grey bold_font">Profiel</td>-->
<!--                            <td class="grey">EFC</td>-->
<!--                        </tr>-->
<!--                        <tr>-->
<!--                            <td class="title grey bold_font">Loadindex</td>-->
<!--                            <td class="grey">75T TL</td>-->
<!--                        </tr>-->
<!--                        <tr>-->
<!--                            <td class="title grey bold_font">Snelheidsindex</td>-->
<!--                            <td class="grey">T</td>-->
<!--                        </tr>-->
<!--                        <tr>-->
<!--                            <td class="title grey bold_font">Serienummer</td>-->
<!--                            <td class="grey">00</td>-->
<!--                        </tr>-->
<!--                        <tr>-->
<!--                            <td class="title grey bold_font">Bruto</td>-->
<!--                            <td class="grey">€76,00</td>-->
<!--                        </tr>-->
<!--                        <tr>-->
<!--                            <td class="title grey bold_font">Label</td>-->
<!--                            <td>-->
<!--                                <span class="icon_parent">-->
<!--                                    <mat-icon>volume_up</mat-icon>-->
<!--                                    <span class="light_font grey">C</span>-->
<!--                                </span>-->
<!--                                <span class="icon_parent">-->
<!--                                    <mat-icon>volume_up</mat-icon>-->
<!--                                    <span class="light_font grey">B</span>-->
<!--                                </span>-->
<!--                                <span class="icon_parent">-->
<!--                                    <mat-icon>volume_up</mat-icon>-->
<!--                                    <span class="light_font grey">68</span>-->
<!--                                </span>-->
<!--                            </td>-->
<!--                        </tr>-->
<!--                    </tbody>-->
<!--                </table>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="right_div bg_red border_radious10 box_shadow">-->
<!--            <div class="detail_table white">-->
<!--                <table>-->
<!--                    <tbody>-->
<!--                        <tr>-->
<!--                            <td class="title bold_font">Vestiging</td>-->
<!--                            <td class="light_font text_right">€42,96</td>-->
<!--                            <td class="input text_center">-->
<!--                                <input type="number" id="vestiging" name="vestiging">-->
<!--                                <a href="" class="shopping_cart_logo display_inline">-->
<!--                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"-->
<!--                                         width="902.86px" height="902.86px" viewBox="0 0 902.86 902.86" style="enable-background:new 0 0 902.86 902.86;"-->
<!--                                         xml:space="preserve">-->
<!--                                        <g>-->
<!--                                          <g>-->
<!--                                            <path d="M671.504,577.829l110.485-432.609H902.86v-68H729.174L703.128,179.2L0,178.697l74.753,399.129h596.751V577.829z-->
<!--                                              M685.766,247.188l-67.077,262.64H131.199L81.928,246.756L685.766,247.188z"/>-->
<!--                                            <path d="M578.418,825.641c59.961,0,108.743-48.783,108.743-108.744s-48.782-108.742-108.743-108.742H168.717-->
<!--                                              c-59.961,0-108.744,48.781-108.744,108.742s48.782,108.744,108.744,108.744c59.962,0,108.743-48.783,108.743-108.744-->
<!--                                              c0-14.4-2.821-28.152-7.927-40.742h208.069c-5.107,12.59-7.928,26.342-7.928,40.742-->
<!--                                              C469.675,776.858,518.457,825.641,578.418,825.641z M209.46,716.897c0,22.467-18.277,40.744-40.743,40.744-->
<!--                                              c-22.466,0-40.744-18.277-40.744-40.744c0-22.465,18.277-40.742,40.744-40.742C191.183,676.155,209.46,694.432,209.46,716.897z-->
<!--                                              M619.162,716.897c0,22.467-18.277,40.744-40.743,40.744s-40.743-18.277-40.743-40.744c0-22.465,18.277-40.742,40.743-40.742-->
<!--                                              S619.162,694.432,619.162,716.897z"/>-->
<!--                                          </g>-->
<!--                                        </g>-->
<!--                                    </svg>-->
<!--                                  </a>-->
<!--                            </td>-->
<!--                        </tr>-->
<!--                        <tr>-->
<!--                            <td class="title bold_font">4 uur</td>-->
<!--                            <td class="light_font text_right">€42,96</td>-->
<!--                            <td class="input text_center">-->
<!--                                <input type="number" id="vestiging" name="vestiging">-->
<!--                                <a href="" class="shopping_cart_logo display_inline">-->
<!--                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"-->
<!--                                         width="902.86px" height="902.86px" viewBox="0 0 902.86 902.86" style="enable-background:new 0 0 902.86 902.86;"-->
<!--                                         xml:space="preserve">-->
<!--                                        <g>-->
<!--                                          <g>-->
<!--                                            <path d="M671.504,577.829l110.485-432.609H902.86v-68H729.174L703.128,179.2L0,178.697l74.753,399.129h596.751V577.829z-->
<!--                                              M685.766,247.188l-67.077,262.64H131.199L81.928,246.756L685.766,247.188z"/>-->
<!--                                            <path d="M578.418,825.641c59.961,0,108.743-48.783,108.743-108.744s-48.782-108.742-108.743-108.742H168.717-->
<!--                                              c-59.961,0-108.744,48.781-108.744,108.742s48.782,108.744,108.744,108.744c59.962,0,108.743-48.783,108.743-108.744-->
<!--                                              c0-14.4-2.821-28.152-7.927-40.742h208.069c-5.107,12.59-7.928,26.342-7.928,40.742-->
<!--                                              C469.675,776.858,518.457,825.641,578.418,825.641z M209.46,716.897c0,22.467-18.277,40.744-40.743,40.744-->
<!--                                              c-22.466,0-40.744-18.277-40.744-40.744c0-22.465,18.277-40.742,40.744-40.742C191.183,676.155,209.46,694.432,209.46,716.897z-->
<!--                                              M619.162,716.897c0,22.467-18.277,40.744-40.743,40.744s-40.743-18.277-40.743-40.744c0-22.465,18.277-40.742,40.743-40.742-->
<!--                                              S619.162,694.432,619.162,716.897z"/>-->
<!--                                          </g>-->
<!--                                        </g>-->
<!--                                    </svg>-->
<!--                                  </a>-->
<!--                            </td>-->
<!--                        </tr>-->
<!--                        <tr>-->
<!--                            <td class="title bold_font">24 uur</td>-->
<!--                            <td class="light_font text_right">€42,96</td>-->
<!--                            <td class="input text_center">-->
<!--                                <input type="number" id="vestiging" name="vestiging">-->
<!--                                <a href="" class="shopping_cart_logo display_inline">-->
<!--                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"-->
<!--                                         width="902.86px" height="902.86px" viewBox="0 0 902.86 902.86" style="enable-background:new 0 0 902.86 902.86;"-->
<!--                                         xml:space="preserve">-->
<!--                                        <g>-->
<!--                                          <g>-->
<!--                                            <path d="M671.504,577.829l110.485-432.609H902.86v-68H729.174L703.128,179.2L0,178.697l74.753,399.129h596.751V577.829z-->
<!--                                              M685.766,247.188l-67.077,262.64H131.199L81.928,246.756L685.766,247.188z"/>-->
<!--                                            <path d="M578.418,825.641c59.961,0,108.743-48.783,108.743-108.744s-48.782-108.742-108.743-108.742H168.717-->
<!--                                              c-59.961,0-108.744,48.781-108.744,108.742s48.782,108.744,108.744,108.744c59.962,0,108.743-48.783,108.743-108.744-->
<!--                                              c0-14.4-2.821-28.152-7.927-40.742h208.069c-5.107,12.59-7.928,26.342-7.928,40.742-->
<!--                                              C469.675,776.858,518.457,825.641,578.418,825.641z M209.46,716.897c0,22.467-18.277,40.744-40.743,40.744-->
<!--                                              c-22.466,0-40.744-18.277-40.744-40.744c0-22.465,18.277-40.742,40.744-40.742C191.183,676.155,209.46,694.432,209.46,716.897z-->
<!--                                              M619.162,716.897c0,22.467-18.277,40.744-40.743,40.744s-40.743-18.277-40.743-40.744c0-22.465,18.277-40.742,40.743-40.742-->
<!--                                              S619.162,694.432,619.162,716.897z"/>-->
<!--                                          </g>-->
<!--                                        </g>-->
<!--                                    </svg>-->
<!--                                  </a>-->
<!--                            </td>-->
<!--                        </tr>-->
<!--                    </tbody>-->
<!--                </table>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</section>-->

<!-- =================================== Velgen Card BOx =================================== -->
<section class="velgen_tab bg_white border_radious10 box_shadow">
    <div [innerHTML]="contentService.velgenContent.data['content-top'][0].content"
         *ngIf="contentService.velgenContent?.data && contentService.velgenContent.data['content-top'][0] &&
       contentService.velgenContent.data['content-top'][0].content" class="velgen_child">
<!--        <h5>Velgen</h5>-->
<!--        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et-->
<!--            dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet-->
<!--            clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,-->
<!--            consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,-->
<!--            sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no-->
<!--            sea takimata sanctus est Lorem ipsum dolor sit amet</p>-->
<!--        <div class="velgen_images">-->
<!--            <div class="img">-->
<!--                <img src="../../../assets/imgs/velgen_img_1_big.png" alt="velgen_img_1">-->
<!--            </div>-->
<!--            <div class="img">-->
<!--                <img src="../../../assets/imgs/velgen_img_2_big.png" alt="velgen_img_2">-->
<!--            </div>-->
<!--            <div class="img">-->
<!--                <img src="../../../assets/imgs/velgen_img_3_big.png" alt="velgen_img_3">-->
<!--            </div>-->
<!--        </div>-->
<!--        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et-->
<!--            dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum</p>-->
    </div>
</section>


<!-- =================================== App Product BOx =================================== -->
<app-product-box>

</app-product-box>

<!-- =================================== Velgen discription section ================================= -->
<section class="description bg_blue white border_radious10 box_shadow text_center">
    <div [innerHTML]="contentService.velgenContent.data['content-bottom'][0].content"
         *ngIf="contentService.velgenContent?.data && contentService.velgenContent.data['content-bottom'][0] &&
       contentService.velgenContent.data['content-bottom'][0].content" class="description_child">
<!--        <p>Kunt u een product niet vinden? Neem dan contact met ons op via de onderstaande nummers. Voor vragen,-->
<!--            opmerkingen of advies kunt u altijd terecht bij onze medewerkers via telefoonnummer 0318-616263 (Ede) en-->
<!--            0342-421111 (Barneveld).</p>-->
    </div>
</section>

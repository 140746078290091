import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userData: BehaviorSubject<any> = new BehaviorSubject<unknown>(null);
  userDataObservable: Observable<any> = this.userData.asObservable();
  activeTabIndex: number = 0;
  status: boolean = false;
  totalCount: number = 0;
  constructor() {
  }

  getTotalCount(){
    const cartInfo: any = JSON.parse(<string>localStorage.getItem('cartValues'));
    let count: number = 0;
    if(cartInfo && cartInfo.length>0){
      cartInfo.map((cart: any) => {
        count += cart.inventory;
        count += cart.quantity24;
        count += cart.quantity48;
      });
    }
    this.totalCount = count;
  }
}

<!-- <div class="cover">


  <form  class="flex-form" (submit)="loginUser()" [formGroup]="loginGroup">


    <label for="from">Dealer login</label>
    <input type="email" name="from" formControlName="email" placeholder="Naam">

    <input type="password" name="to" formGroupName="password" [placeholder]="'Wachtwoord'">

    <input type="submit" value="INLOGGEN">

  </form>

</div> -->

<section class="login">
  <div class="wrapper">
    <div class="login_child">
      <form  class="flex-form" (submit)="loginUser()" [formGroup]="loginGroup">

        <div class="form_content">
            <h5>
              <label for="from" class="white regular_font pl20">Dealer log in</label>
            </h5>
            <input type="email" class="input_naam" name="from" formControlName="email" placeholder="Naam">
            <input type="password" class="input_wachtwoord" name="to" formControlName="password" [placeholder]="'Wachtwoord'">
          <div style="text-align:center">
            <button mat-raised-button class="button_inloggen bg_red white" [class.spinner]="loginProgress" [disabled]="loginProgress"
                    (click)="loginUser()">
              INLOGGEN</button>
          </div>
        </div>

      </form>
    </div>
  </div>
</section>

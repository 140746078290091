 <!-- <div class="productBoxes">
    <div class="productBox">
      <div class="image">
        <img src="/assets/imgs/logo.png" alt="">
      </div>
      <div>
        <h3>
          Overheerlijke herfst actie
        </h3>
        <p>
          Bij afname van twee banden een gratis Unox rookworst!
        </p>
      </div>
    </div>
    <div class="productBox">
      <div class="image">
        <img src="/assets/imgs/logo.png" alt="">
      </div>
      <div>
        <h3>
          Overheerlijke herfst actie
        </h3>
        <p>
          Bij afname van twee banden een gratis Unox rookworst!
        </p>
      </div>
    </div>
  </div> -->

  <!--
  /* --------------======================
-------------------------------- HTML Stucture of Hardik Nakum Prodcut Box Component
======================-------------- */
 -->
<section class="products">
  <div class="product_boxes text_center">
    <div [innerHTML]="contentService.productContent?.data['content-bottom-left'][0].content"
         *ngIf="contentService?.productContent?.data && contentService.productContent.data['content-bottom-left'][0] &&
       contentService.productContent.data['content-bottom-left'][0].content" class="product_box product_box_1 bg_white box_shadow border_radious10">
<!--      <div class="prodcuts_img">-->
<!--        <img src="../../../assets/imgs/product_card_img_1.png" alt="product_card_img_1">-->
<!--      </div>-->
<!--      <div class="procudct_discription">-->
<!--        <h5 class="red">-->
<!--          Overheerlijke herfst actie-->
<!--        </h5>-->
<!--        <p class="grey">-->
<!--          Bij afname van twee banden een gratis Unox rookworst!-->
<!--        </p>-->
<!--      </div>-->
    </div>

    <div [innerHTML]="contentService.productContent.data['content-bottom-center'][0].content"
         *ngIf="contentService?.productContent?.data && contentService.productContent.data['content-bottom-center'][0] &&
       contentService.productContent.data['content-bottom-center'][0].content" class="product_box product_box_2 bg_white box_shadow border_radious10">
<!--      <div class="prodcuts_img">-->
<!--        <img src="../../../assets/imgs/product_card_img_2.png" alt="product_card_img_2">-->
<!--      </div>-->
<!--      <div class="procudct_discription">-->
<!--        <h5 class="red">-->
<!--          All season banden sets-->
<!--        </h5>-->
<!--        <p class="grey">-->
<!--          Vraag een off erte aan!-->
<!--        </p>-->
<!--      </div>-->
    </div>

    <div [innerHTML]="contentService.productContent.data['content-bottom-right'][0].content"
         *ngIf="contentService?.productContent && contentService.productContent.data['content-bottom-right'][0] &&
       contentService.productContent.data['content-bottom-right'][0].content" class="product_box product_box_3 bg_white box_shadow border_radious10">
<!--      <div class="prodcuts_img">-->
<!--        <img src="../../../assets/imgs/product_card_img_3.png" alt="product_card_img_3">-->
<!--      </div>-->
<!--      <div class="procudct_discription">-->
<!--        <h5 class="red">-->
<!--          Ook bij ABC-->
<!--        </h5>-->
<!--        <p class="grey">-->
<!--          Originele 20” breedset-->
<!--        </p>-->
<!--        <p>-->
<!--          Mercedes AMG-->
<!--        </p>-->
<!--      </div>-->
    </div>
  </div>
</section>

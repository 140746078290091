<section class="account_tab">
    <div class="account_tab_child">
        <div class="account_left_part">
            <!-- upper part table -->
            <div class="account bg_white border_radious10 box_shadow">
                <div class="account_child">
                    <h5>Account details</h5>
                    <div class="detail_table">
                        <table>
                            <tbody>
                                <tr>
                                    <td class="title grey bold_font">Gebruikersnaam</td>
                                    <td class="grey">{{userData?.Name}}</td>
                                </tr>
                                <tr>
                                    <td class="title grey bold_font">Bedrijfsnaam</td>
                                    <td class="grey">{{userData?.WebContactName}}</td>
                                </tr>
                                <tr>
                                    <td class="title grey bold_font">Contact</td>
                                    <td class="grey">{{userData?.WebContactNo}}</td>
                                </tr>
                                <tr>
                                    <td class="title grey bold_font">Adres</td>
                                    <td class="grey">{{userData?.Address}}</td>
                                </tr>
                                <tr>
                                    <td class="title grey bold_font">Postcode</td>
                                    <td class="grey">{{userData?.Post_Code}}</td>
                                </tr>
                                <tr>
                                    <td class="title grey bold_font">Plaats</td>
                                    <td class="grey">{{userData?.City}}</td>
                                </tr>
                                <tr>
                                    <td class="title grey bold_font">E-mailadres</td>
                                  <td class="grey">{{userData?.E_Mail}}</td>
                                </tr>
                                <tr>
                                    <td class="title grey bold_font">Telefoon</td>
                                    <td class="grey">{{userData?.Phone_No}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="account_buttons">
                        <a href="javascript:void(0)" (click)="logoutUser()"
                           class="uitloggen_button bg_red white bold_font">UITLOGGEN</a>
<!--                        <a href="javascript:void(0)"  class="aanpassen_button bg_blue white bold_font">AANPASSEN</a>-->
                    </div>
                </div>
            </div>

            <!-- Lower part two table with pagination -->
          <div class="lower_data_tables">
            <div class="lower_data_table bg_blue white bold_font">
<!--              <div class="name">Facturen</div>-->
              <div class="datatable">
                <mat-table [dataSource]="invoiceData" matSort>
                  <ng-container matColumnDef="number">
                     <mat-header-cell *matHeaderCellDef mat-sort-header>Facturen</mat-header-cell>
                    <mat-cell *matCellDef="let row" class="regular_font"> {{row.No}} </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="download">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                    <td mat-cell *matCellDef="let element"
                        class="regular_font" (click)="downloadFile(element.Notification_No)">
                      <a href="javascript:void(0)" class="fa fa-download white" aria-hidden="true"></a>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="invoiceColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: invoiceColumns;"></tr>
                </mat-table>
                <mat-paginator *ngIf="invoiceLength > 6" [length]="invoiceLength" [pageSize]="6"></mat-paginator>
              </div>
            </div>
          </div>
        </div>

        <!-- Right Part table with Button -->
        <div *ngIf="!alreadyPlacedOrder" class="account_right_part">
            <div class="account_right_table white">
                <div class="account_right_table_child ">
                  <div class="table_data">
                    <div class="tableWrapper">
                       <table *ngIf="dataSource" [style.display]="isLoadingData ? 'none' : 'table'" mat-table
                             [dataSource]="dataSource"
                             class="mat-elevation-z8"
                             matSort>

                        <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->

                        <!-- Position Column -->
                        <ng-container matColumnDef="no">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14.934" height="14.935" viewBox="0 0 14.934 14.935"><defs><style>.a{fill:#5a5a5a;}</style></defs><path class="a" d="M7.468,4.667a2.8,2.8,0,1,0,2.8,2.8A2.8,2.8,0,0,0,7.468,4.667Zm7.188,2.348-2.762-1.38.977-2.929a.5.5,0,0,0-.639-.639L9.3,3.045,7.92.28a.505.505,0,0,0-.9,0L5.636,3.042,2.7,2.065a.5.5,0,0,0-.639.639l.977,2.929L.28,7.015a.505.505,0,0,0,0,.9L3.042,9.3l-.977,2.932a.5.5,0,0,0,.639.639l2.929-.977,1.38,2.762a.505.505,0,0,0,.9,0L9.3,11.893l2.929.977a.5.5,0,0,0,.639-.639L11.887,9.3l2.762-1.38a.506.506,0,0,0,.006-.907Zm-4.548,3.092a3.733,3.733,0,1,1,0-5.28A3.738,3.738,0,0,1,10.107,10.107Z"/></svg>/
                            <svg xmlns="http://www.w3.org/2000/svg" width="13.069" height="14.935" viewBox="0 0 13.069 14.935"><defs><style>.a{fill:#5a5a5a;}</style></defs><path class="a" d="M12.842,10.07,11.856,9.5l.758-.2a.451.451,0,0,0,.318-.551l-.117-.435a.451.451,0,0,0-.551-.318L10.2,8.547,8.335,7.468,10.2,6.391l2.065.554a.451.451,0,0,0,.551-.318l.117-.435a.451.451,0,0,0-.318-.551l-.758-.2.986-.569a.453.453,0,0,0,.166-.615l-.452-.782a.453.453,0,0,0-.615-.166l-.986.569.2-.758a.451.451,0,0,0-.318-.551L10.4,2.447a.451.451,0,0,0-.551.318L9.3,4.831,7.466,5.887V3.626L9.033,2.059a.468.468,0,0,0,0-.659l-.33-.33a.468.468,0,0,0-.659,0l-.578.575V.467A.468.468,0,0,0,7,0H6.066A.468.468,0,0,0,5.6.467V1.645l-.575-.575a.468.468,0,0,0-.659,0l-.333.33a.463.463,0,0,0,0,.659L5.6,3.626V5.887L3.767,4.831,3.213,2.765a.451.451,0,0,0-.551-.318l-.435.117a.451.451,0,0,0-.318.551l.2.758L1.127,3.3a.45.45,0,0,0-.615.166L.06,4.25a.45.45,0,0,0,.166.615l.986.569-.758.2a.452.452,0,0,0-.321.554l.117.435A.451.451,0,0,0,.8,6.945l2.065-.554L4.727,7.468,2.866,8.544.8,7.99a.451.451,0,0,0-.551.318l-.117.435a.451.451,0,0,0,.318.551l.758.2-.986.572a.453.453,0,0,0-.166.615l.452.782a.453.453,0,0,0,.615.166l.986-.569-.2.758a.451.451,0,0,0,.318.551l.435.117a.451.451,0,0,0,.551-.318l.554-2.065L5.6,9.052v2.261L4.03,12.879a.463.463,0,0,0,0,.659l.33.33a.468.468,0,0,0,.659,0l.575-.575v1.176a.468.468,0,0,0,.467.467h.933a.468.468,0,0,0,.467-.467V13.29l.575.575a.468.468,0,0,0,.659,0l.33-.33a.468.468,0,0,0,0-.659L7.466,11.309V9.049L9.3,10.1l.554,2.065a.451.451,0,0,0,.551.318l.435-.117a.451.451,0,0,0,.318-.551l-.2-.758.986.569a.45.45,0,0,0,.615-.166l.452-.782A.446.446,0,0,0,12.842,10.07Z" transform="translate(0.002)"/></svg>
                          </th>
                          <td mat-cell *matCellDef="let element">
                            <svg *ngIf="element.Position_Group_Code == 'PZ' || element.Position_Group_Code == '4Z' ||
                  element.Position_Group_Code == 'PA'"
                                 xmlns="http://www.w3.org/2000/svg" width="14.934"
                                 height="14.935"
                                 viewBox="0 0 14.934 14.935"><defs><style>.a{fill:#5a5a5a;}</style></defs><path class="a" d="M7.468,4.667a2.8,2.8,0,1,0,2.8,2.8A2.8,2.8,0,0,0,7.468,4.667Zm7.188,2.348-2.762-1.38.977-2.929a.5.5,0,0,0-.639-.639L9.3,3.045,7.92.28a.505.505,0,0,0-.9,0L5.636,3.042,2.7,2.065a.5.5,0,0,0-.639.639l.977,2.929L.28,7.015a.505.505,0,0,0,0,.9L3.042,9.3l-.977,2.932a.5.5,0,0,0,.639.639l2.929-.977,1.38,2.762a.505.505,0,0,0,.9,0L9.3,11.893l2.929.977a.5.5,0,0,0,.639-.639L11.887,9.3l2.762-1.38a.506.506,0,0,0,.006-.907Zm-4.548,3.092a3.733,3.733,0,1,1,0-5.28A3.738,3.738,0,0,1,10.107,10.107Z"/></svg>
                            {{element.Position_Group_Code == 'PA' ? '/' : ''}}
                            <svg *ngIf="element.Position_Group_Code == 'PW' || element.Position_Group_Code == '4W' ||
                  element.Position_Group_Code == 'PA'"
                                 xmlns="http://www.w3.org/2000/svg" width="13.069"
                                 height="14.935"
                                 viewBox="0 0 13.069 14.935"><defs><style>.a{fill:#5a5a5a;}</style></defs><path class="a" d="M12.842,10.07,11.856,9.5l.758-.2a.451.451,0,0,0,.318-.551l-.117-.435a.451.451,0,0,0-.551-.318L10.2,8.547,8.335,7.468,10.2,6.391l2.065.554a.451.451,0,0,0,.551-.318l.117-.435a.451.451,0,0,0-.318-.551l-.758-.2.986-.569a.453.453,0,0,0,.166-.615l-.452-.782a.453.453,0,0,0-.615-.166l-.986.569.2-.758a.451.451,0,0,0-.318-.551L10.4,2.447a.451.451,0,0,0-.551.318L9.3,4.831,7.466,5.887V3.626L9.033,2.059a.468.468,0,0,0,0-.659l-.33-.33a.468.468,0,0,0-.659,0l-.578.575V.467A.468.468,0,0,0,7,0H6.066A.468.468,0,0,0,5.6.467V1.645l-.575-.575a.468.468,0,0,0-.659,0l-.333.33a.463.463,0,0,0,0,.659L5.6,3.626V5.887L3.767,4.831,3.213,2.765a.451.451,0,0,0-.551-.318l-.435.117a.451.451,0,0,0-.318.551l.2.758L1.127,3.3a.45.45,0,0,0-.615.166L.06,4.25a.45.45,0,0,0,.166.615l.986.569-.758.2a.452.452,0,0,0-.321.554l.117.435A.451.451,0,0,0,.8,6.945l2.065-.554L4.727,7.468,2.866,8.544.8,7.99a.451.451,0,0,0-.551.318l-.117.435a.451.451,0,0,0,.318.551l.758.2-.986.572a.453.453,0,0,0-.166.615l.452.782a.453.453,0,0,0,.615.166l.986-.569-.2.758a.451.451,0,0,0,.318.551l.435.117a.451.451,0,0,0,.551-.318l.554-2.065L5.6,9.052v2.261L4.03,12.879a.463.463,0,0,0,0,.659l.33.33a.468.468,0,0,0,.659,0l.575-.575v1.176a.468.468,0,0,0,.467.467h.933a.468.468,0,0,0,.467-.467V13.29l.575.575a.468.468,0,0,0,.659,0l.33-.33a.468.468,0,0,0,0-.659L7.466,11.309V9.049L9.3,10.1l.554,2.065a.451.451,0,0,0,.551.318l.435-.117a.451.451,0,0,0,.318-.551l-.2-.758.986.569a.45.45,0,0,0,.615-.166l.452-.782A.446.446,0,0,0,12.842,10.07Z" transform="translate(0.002)"/></svg>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="fabricant">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fabrikant </th>
                          <td mat-cell *matCellDef="let element" class="border-right">
                            <a href="javascript:void(0)" aria-hidden="true" (click)="removeRow(element)" class="fa fa-times cross red pr15"></a>
                              {{element.Manufacturer_Name}}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="type">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
                          <td mat-cell *matCellDef="let element" class="border-right">
                              {{element.Description}}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="lisi">
                          <th mat-header-cell *matHeaderCellDef> LI/SI </th>
                          <td mat-cell *matCellDef="let element" class="border-right"> {{element.Description_2}} </td>
                        </ng-container>
                        <ng-container matColumnDef="bruto">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Bruto </th>
                          <td mat-cell *matCellDef="let element" class="border-right"> &euro;{{element.Unit_Price}} </td>
                        </ng-container>
                        <ng-container matColumnDef="label">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Label </th>
                          <td mat-cell *matCellDef="let element">

                            <svg  width="20px" height="20px" xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px"
                                  viewBox="0 0 209.774 209.774" style="enable-background:new 0 0 209.774 209.774;"
                                  xml:space="preserve">
                        <path
                          d="M197.575,1.312c-3.418-2.34-8.085-1.465-10.425,1.953l-16.568,24.204c-0.029,0.042-0.058,0.085-0.086,0.128l-5.491,8.388  c-3.939,6.016-6.032,12.997-6.052,20.188l-0.294,105.754c-0.005,1.73-1.414,3.134-3.146,3.134c-0.003,0-0.006,0-0.009,0l-0.211,0  c-1.133-0.003-1.877-0.582-2.22-0.927s-0.917-1.092-0.914-2.225l0.168-60.271c0.033-12.017-9.518-21.86-21.438-22.29l0.12-43.019  c0.015-5.298-2.034-10.284-5.77-14.041c-3.735-3.756-8.71-5.833-14.008-5.848L29.175,16.21c-10.906,0-19.803,8.873-19.833,19.778  L8.935,181.987c-0.019,6.907,4.822,12.704,11.294,14.173l-0.016,5.859c-0.011,4.142,3.337,7.509,7.479,7.521l84,0.234  c0.007,0,0.014,0,0.021,0c1.981,0,3.883-0.784,5.289-2.182c1.411-1.402,2.206-3.308,2.211-5.297l0.016-5.859  c6.477-1.434,11.353-7.211,11.372-14.11l0.245-87.96c1.652,0.173,3.183,0.902,4.371,2.096c1.366,1.373,2.115,3.196,2.109,5.132  l-0.168,60.271c-0.014,4.846,1.861,9.407,5.278,12.844c3.417,3.436,7.968,5.336,12.818,5.35l0.211,0c0.017,0,0.033,0,0.05,0  c9.98,0,18.114-8.105,18.143-18.092l0.294-105.755c0.005-1.801,0.241-3.576,0.673-5.299c2.438,1.564,5.325,2.488,8.43,2.497  c0.007,0,0.039-0.004,0.044,0c8.66,0,15.725-7.046,15.75-15.706c0.012-4.207-1.616-8.167-4.582-11.15  c-0.966-0.971-2.045-1.786-3.196-2.464l8.456-12.353C201.867,8.319,200.993,3.651,197.575,1.312z M103.653,43.876l-0.089,32  L36.897,75.69l0.089-32L103.653,43.876z" />
                              <circle cx="183.1" cy="37.66" r="8.25" />

                    </svg>{{element.Fuel_Efficiency}}

                            <svg  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px"
                                  width="20px" height="20px" viewBox="0 0 264.564 264.564"
                                  style="enable-background:new 0 0 264.564 264.564;" xml:space="preserve">
                        <g>
                            <g>
                                <path
                                  d="M132.281,264.564c51.24,0,92.931-41.681,92.931-92.918c0-50.18-87.094-164.069-90.803-168.891L132.281,0l-2.128,2.773    c-3.704,4.813-90.802,118.71-90.802,168.882C39.352,222.883,81.042,264.564,132.281,264.564z" />
                            </g>
                        </g>

                    </svg>{{element.Wet_Grip}}

                            <svg  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="20px" height="20px" id="Layer_1"
                                  x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;"
                                  xml:space="preserve">
                        <g>
                            <g>
                                <path
                                  d="M240.419,42.298l-129.114,96.836H16.696C7.475,139.133,0,146.608,0,155.829v200.35c0,9.221,7.475,16.696,16.696,16.696    h94.609l129.114,96.836c10.92,8.19,26.713,0.47,26.713-13.357V55.654C267.133,41.928,251.415,34.051,240.419,42.298z     M100.175,339.483H33.392V172.525h66.783V339.483z M233.741,422.962l-100.175-75.131V164.177l100.175-75.131V422.962z" />
                            </g>
                        </g>
                              <g>
                            <g>
                                <path
                                  d="M438.761,78.919c-6.519-6.52-17.091-6.52-23.611,0c-6.52,6.52-6.52,17.091,0,23.611    c40.92,40.92,63.457,95.423,63.458,153.47c0.001,58.048-22.535,112.555-63.458,153.478c-6.52,6.52-6.52,17.091,0,23.611    c3.26,3.26,7.533,4.891,11.806,4.891s8.546-1.63,11.805-4.891c47.23-47.23,73.24-110.122,73.239-177.09    C511.999,189.034,485.989,126.145,438.761,78.919z" />
                            </g>
                        </g>
                              <g>
                            <g>
                                <path
                                  d="M391.545,126.124c-6.52-6.523-17.092-6.524-23.611-0.006c-6.521,6.518-6.525,17.09-0.007,23.611    c58.571,58.6,58.571,153.949,0,212.549c-6.518,6.521-6.516,17.093,0.007,23.611c3.26,3.259,7.531,4.887,11.803,4.887    c4.274,0,8.549-1.632,11.808-4.893C463.126,314.268,463.126,197.74,391.545,126.124z" />
                            </g>
                        </g>
                              <g>
                            <g>
                                <path
                                  d="M344.342,173.373c-6.518-6.522-17.09-6.522-23.611-0.004c-6.521,6.519-6.524,17.09-0.004,23.611    c32.532,32.546,32.517,85.516-0.032,118.079c-6.518,6.523-6.516,17.093,0.006,23.612c3.26,3.259,7.532,4.887,11.803,4.887    c4.274,0,8.548-1.631,11.807-4.893C389.871,293.085,389.886,218.936,344.342,173.373z" />
                            </g>
                        </g>

                    </svg>{{element.Noise_Class_Type}}

                          </td>
                        </ng-container>
                        <ng-container matColumnDef="vestiging">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Vestiging </th>
                          <td mat-cell *matCellDef="let element" class="number_input border-right">
                              <span class="text">&euro;{{element.Unit_Price}}</span>
                              <span class="input">
                              <span class="inner_input">
                              <input type="number" name="unit_price" id="unist_sprice" min="0"
                                     [(ngModel)]="element.inventory" (change)="onValueChange(element)">
                              <a href="javascript:void(0)"  (click)="removeField('inventory', element)" class="fa fa-times cross" aria-hidden="true"></a>
                            </span>
                            </span>

                          </td>
                        </ng-container>
                        <ng-container matColumnDef="uur">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> 24 uur </th>
                          <td mat-cell *matCellDef="let element" class="number_input_2 border-right">
                              <span class="text">&euro;{{element.Price48}}</span>
                              <span class="input">
                              <span class="inner_input">
                                <input type="number" name="unit_price" id="usnit_sprice" min="0"
                                       [(ngModel)]="element.quantity48" (change)="onValueChange(element)">
                              <a href="javascript:void(0)"  (click)="removeField('i48',element)" class="fa fa-times cross" aria-hidden="true"></a>
                              </span>
                            </span>

                          </td>
                        </ng-container>
                        <ng-container matColumnDef="uurs">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> 4 uur </th>
                          <td mat-cell *matCellDef="let element" class="number_input_2 border-right">
                              <span class="text">&euro;{{element.Price24}}</span>
                              <span class="input">
                              <span class="inner_input">
                                <input type="number" name="unit_price" id="usnit_price" min="0"
                                       [(ngModel)]="element.quantity24" (change)="onValueChange(element)">
                              <a href="javascript:void(0)"  (click)="removeField('i24', element)" class="fa fa-times cross" aria-hidden="true"></a>
                              </span>
                            </span>
                          </td>
                        </ng-container>
                         <ng-container matColumnDef="reference">
                          <th mat-header-cell *matHeaderCellDef> Referentie </th>
                          <td mat-cell *matCellDef="let element" class="number_input_2 border-right">
                              <span class="input w100">
                              <span class="inner_input referenceField">
                                <input type="text" [(ngModel)]="element.reference" name="reference" class="">
                              </span>
                            </span>
                          </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      </table>
                      <div class="spinnerWrapper" *ngIf="isLoadingData">
                        <div class="spinner"></div>
                      </div>
                      <!-- <mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator> -->
                    </div>
                    <div class="tableWrapper noData colorMain" *ngIf="resultsLength == 0">
                      <span>No products in cart</span>
                    </div>
                  </div>
                </div>
            </div>
            <div *ngIf="!orderPlaced" class="plaats_order_button">
                <a href="javascript:void(0)"  class="bg_blue white bold_font text_left uppercase" (click)="navigateTo()">terug naar het overzicht</a>
                <a href="javascript:void(0)"  class="bg_red white bold_font text_right" (click)="placeorder()">PLAATS ORDER</a>
            </div>
        </div>
        <div *ngIf="alreadyPlacedOrder" class="account_right_part">
            <div class="account_right_table white">
                <div class="account_right_table_child text_center colorMain orderPlacedLabel">
<!--                  Uw order is geplaatst, er wordt zo spoedig mogelijk contact opgenomen.-->
                  Er zijn geen bestellingen aanwezig in de winkelwagen.
                </div>
            </div>
        </div>
    </div>
</section>

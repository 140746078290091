<div class="tabs">
  <div class="wrapper">
    <div class="flex-r br10">
      <div class="tab" *ngFor="let option of options" [routerLink]= option.routerLink
           [routerLinkActive]="option.routerLink ? 'active' : ''">
        <div class="tab-content">
          <div class="icon">
            <img src={{option.image}} alt="home_icon">
          </div>
          <h4 class="blue tab-label">{{option.name}}</h4>
        </div>
      </div>
    </div>

    <div [@routeAnimations]="prepareRoute(outlet)" class="content">
      <router-outlet #outlet="outlet"></router-outlet>
    </div>

<!--    <mat-tab-group [@.disabled]="true" (selectedTabChange)="tabSwiched()"-->
<!--                   [(selectedIndex)]="userService.activeTabIndex" [disablePagination]="true">-->
<!--      &lt;!&ndash; <div class="home_tab"> &ndash;&gt;-->
<!--        <mat-tab>-->
<!--          <ng-template mat-tab-label >-->
<!--            <div class="icon">-->
<!--              <img src="../../../assets/imgs/home_icon.png" alt="home_icon">-->
<!--            </div>-->
<!--            <h4 class="blue">Home</h4>-->
<!--          </ng-template>-->
<!--         <app-home-tab [isMessageVisible]="showLoginMessage"></app-home-tab>-->
<!--        </mat-tab>-->
<!--      &lt;!&ndash; </div> &ndash;&gt;-->

<!--      &lt;!&ndash; <div class="banden_tab"> &ndash;&gt;-->
<!--        <mat-tab>-->
<!--          <ng-template mat-tab-label >-->
<!--            <div class="icon">-->
<!--              <img src="../../../assets/imgs/banden_icon.png" alt="banden_icon">-->
<!--            </div>-->
<!--            <h4 class="blue">Banden</h4>-->
<!--          </ng-template>-->
<!--          <app-wheel-tab [switching]="tabSwitchedDone"></app-wheel-tab>-->
<!--        </mat-tab>-->
<!--      &lt;!&ndash; </div> &ndash;&gt;-->

<!--      &lt;!&ndash; <div class="velgen_tab"> &ndash;&gt;-->
<!--        <mat-tab >-->
<!--          <ng-template mat-tab-label>-->
<!--            <div class="icon">-->
<!--              <img src="../../../assets/imgs/velgen_icon.png" alt="velgen_icon">-->
<!--            </div>-->
<!--            <h4 class="blue">Velgen</h4>-->
<!--          </ng-template>-->
<!--          <app-rim-tab></app-rim-tab>-->
<!--        </mat-tab>-->
<!--      &lt;!&ndash; </div> &ndash;&gt;-->

<!--      &lt;!&ndash; <div class="account_tab"> &ndash;&gt;-->
<!--        <mat-tab>-->
<!--          <ng-template mat-tab-label>-->
<!--            <div class="icon">-->
<!--              <img src="../../../assets/imgs/account_icon.png" alt="account_icon">-->
<!--            </div>-->
<!--            <h4 class="blue">Account</h4>-->
<!--          </ng-template>-->
<!--          <app-account-tab [switching]="tabSwitchedDone"></app-account-tab>-->
<!--        </mat-tab>-->
<!--      &lt;!&ndash; </div> &ndash;&gt;-->
<!--    </mat-tab-group>-->

  </div>
</div>
<!--<router-outlet></router-outlet>-->

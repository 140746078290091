import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {UserService} from "../../services/user.service";
import {ToastrService} from "ngx-toastr";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  apiUrl: string = ''
  loginProgress: boolean = false;
  loginGroup: FormGroup = new FormGroup({
      email: new FormControl('', Validators.email),
      password: new FormControl('', Validators.minLength(4))
    });
  constructor(private http: HttpClient, private toastr: ToastrService, private userService: UserService) { }
  loginUser() {
    // console.log('login')
    this.loginProgress = true;
    // console.log(this.loginGroup.value);
    const url = this.apiUrl + '/web-shop-login';
    this.http.post(url, this.loginGroup.value).subscribe((res: any) => {
      const userInfo = res.userData;
      delete userInfo.Password;
      localStorage.setItem('user', JSON.stringify(userInfo));
      this.userService.userData.next(res.userData);
      this.loginProgress = false;
      this.toastr.success('Gebruiker ingelogd succesvol');
      this.userService.status = true;
      window.location.reload();
    }, error => {
      this.loginProgress = false;
      this.toastr.error(error.error.message);
      this.userService.status = false;
    });
  }
  ngOnInit(): void {
    this.apiUrl = environment.url;
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {ContentService} from "../../services/content.service";

@Component({
  selector: 'app-home-tab',
  templateUrl: './home-tab.component.html',
  styleUrls: ['./home-tab.component.scss']
})
export class HomeTabComponent implements OnInit {
  apiUrl: string = '';
  content: any;
  @Input() isMessageVisible: any;
  constructor(public http: HttpClient,
              public contentService: ContentService) {}

  ngOnInit(): void {

  }

}

import { trigger, state, style, transition,animate, group, query, stagger, keyframes } from '@angular/animations';

export const slideLeftRight = [
  trigger('animSlider', [
    transition(':increment', [
        query(':enter, :leave', style({ position: 'absolute', width: '100%' }), { optional: true }),
        group([
          query(':enter', [style({ transform: 'translateX(100%)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))], {
            optional: true,
          }),
          query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(-100%)' }))], {
            optional: true,
          }),
        ]),
      ]

    ),
    transition(':decrement', [
        query(':enter, :leave', style({ position: 'absolute', width: '100%' }), { optional: true }),
        group([
          query(':enter', [style({ transform: 'translateX(-100%)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))], {
            optional: true,
          }),
          query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(100%)' }))], {
            optional: true,
          }),
        ]),
      ]
    ),
  ]),
  trigger('routeAnimations',[

    transition('* <=> *', [
      // Set a default  style for enter and leave
      query(':enter, :leave', [
        style({
          position: 'absolute',
          left: 0,
          width: '100%',
          opacity: 0,
          transform: ' translateY(15%)',
        }),
      ], {optional: true}),
      // Animate the new page in
      // query(':leave', [
      //     animate('100ms ease', style({ opacity: 0, transform: ' translateY(-100%)' })),
      //   ], {optional: true}),
      query(':enter', [
        animate('500ms ease', style({ opacity: 1, transform: ' translateY(0)' })),
      ], {optional: true}),

    ]),
  ]),
  trigger('blockAnimations',[
    transition('* <=> *', [
      query(':enter, :leave', style({ position: 'absolute', left: 0,  }), { optional: true }),
      query(':leave', style({ transform: 'translateX(0%)' }), { optional: true }),
      query(':enter', style({ transform: 'translateX(100%)' }), { optional: true }),
      group([
        query(':leave', [
          animate('.3s ease-in-out', style({ transform: 'translateX(-100%)' })),
        ], { optional: true }),
        query(':enter', [
          animate('.3s ease-in-out', style({ transform: 'translateX(0%)' })),
        ], { optional: true })
      ])
    ]),
  ]),
  trigger('zoomInOut',[
    transition(':enter', [
      style({
        transform:'scle(0.5)',
        opacity: 0
      }),
      animate('500ms',
        style({
          transform:'scale(1)',
          opacity:1
        }))

    ]),
  ]),
]

import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {UserService} from "../../services/user.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() gotoCart: EventEmitter<any> = new EventEmitter();
  constructor(public router: Router,
              public user: UserService,
              public toastr: ToastrService) { }

  ngOnInit(): void {
  }

  navigateTo(){
    const cartValue = localStorage.getItem('cartValues');
    if(cartValue){
      this.router.navigate(['account']);
    }
    else {
      this.toastr.error('Uw winkelwagen is leeg');
    }
  }

}

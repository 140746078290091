import {Component, OnInit} from '@angular/core';
import {UserService} from "./services/user.service";
import {Router} from "@angular/router";
import {ContentService} from "./services/content.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'abc-banden';
  userData: any = null;
  cartChanges: boolean = false;
  constructor(private userService: UserService,
              public router: Router,
              public contentService: ContentService) {
    const userStr = localStorage.getItem('user');
    const userData = userStr ? JSON.parse(userStr) : null;
    this.userService.userData.next(userData);
    this.userService.userDataObservable.subscribe((user: any) => {
      this.userData = user;
    });
    if (this.userData){
      this.userService.status = true;
    }
    else {
      this.userService.status = false;
    }
  }
  switchTocartView() {
    this.cartChanges = !this.cartChanges;
  }

  async ngOnInit() {
    // console.log('app');
    this.contentService.getProductContent()
      .subscribe(res => {
        // console.log(res);
        this.contentService.productContent = res;
      });
    this.contentService.getHomeContent()
      .subscribe(res => {
        // console.log(res);
        this.contentService.homeContent = res;
      });
    this.contentService.getVelgenContent()
      .subscribe( res => {
        // console.log(res);
        this.contentService.velgenContent = res;
      });

    if (!this.userData) {
      this.router.navigate(['home']);
    } else {
      this.userService.status = true;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ContentService} from '../../services/content.service';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-rim-tab',
  templateUrl: './rim-tab.component.html',
  styleUrls: ['./rim-tab.component.scss']
})
export class RimTabComponent implements OnInit {
  apiUrl: string= ''
  content: any;

  constructor(public http: HttpClient,
              public contentService: ContentService) { }

  ngOnInit(): void {

  }

}

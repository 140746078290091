import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {ContentService} from "../../services/content.service";

@Component({
  selector: 'app-product-box',
  templateUrl: './product-box.component.html',
  styleUrls: ['./product-box.component.scss']
})
export class ProductBoxComponent implements OnInit {

  content: any;
  apiUrl: string = '';

  constructor(public  http: HttpClient,
              public contentService: ContentService) { }

  ngOnInit(): void {}

}

import {Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {UserService} from "../../services/user.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import {ContentService} from "../../services/content.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-account-tab',
  templateUrl: './account-tab.component.html',
  styleUrls: ['./account-tab.component.scss']
})
export class AccountTabComponent implements OnInit, OnChanges {
  apiUrl: string = '';
  displayedColumns: string[] = ['fabricant', 'type', 'vestiging', 'uurs', 'uur','reference'];
  invoiceColumns: string[] = ['number', 'download'];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  resultsLength = 0;
  invoiceLength = 0;
  cartData: any = [];
  userData: any = null;
  dataSource!: MatTableDataSource<any>;
  invoiceData!: MatTableDataSource<any>;
  isLoadingData: boolean = false;
  alreadyPlacedOrder: boolean = false;
  cartValues: any;
  allOrders: any[] = [];
  userId: string = '';
  invoice:any;
  files: any[]=[];
  fileData: any;

  @Input() switching: any;
  constructor(private userService: UserService,
              public http: HttpClient,
              private toastr: ToastrService,
              public router: Router) { }
  ngOnChanges() {
    this.isLoadingData = true;
    const localDataStr = localStorage.getItem('cartValues');
    this.cartData = localDataStr ? JSON.parse(localDataStr) : [];
    this.alreadyPlacedOrder = localStorage.getItem('cartValues') == '1';
    // this.alreadyPlacedOrder = this.cartData.length == 0;
    this.dataSource = new MatTableDataSource(this.cartData);
    this.resultsLength = this.cartData.length;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    setTimeout(() => {
      this.isLoadingData = false;
    }, 1000);
  }

  logoutUser() {
    localStorage.removeItem('user');
    this.userService.userData.next(null);
    window.location.reload();
  }
  ngOnInit(): void {
    this.apiUrl = environment.url;
    // console.log(this.apiUrl);
    this.isLoadingData = true;
    const localDataStr = localStorage.getItem('cartValues');
    this.cartData = localDataStr ? JSON.parse(localDataStr) : [];
    this.alreadyPlacedOrder = this.cartData.length == 0;
    this.userService.userDataObservable.subscribe((user: any) => {
      this.userData = user;
    });
    // this.contentService.getFileDetails()
    const userData = localStorage.getItem('user');
    const url = this.apiUrl + '/get-sales-invoice-list';
    if (typeof userData === "string") {
      this.userId = (JSON.parse(userData).No);
    }
    this.http.post(url, { 'customer_id': this.userId})
      .subscribe( res => {
        // @ts-ignore
        this.invoice = res;
        // console.log(res)
        // this.invoice.data.map( (value: any) => {
        //     this.files.push({
        //       num: value.No,
        //       notification: value.Notification_No
        //     });
        //   });
        this.invoiceData = new MatTableDataSource(this.invoice.data);
        this.invoiceLength = this.invoice?.data?.length;
        this.invoiceData.paginator = this.paginator;
        this.invoiceData.sort = this.sort;
        // console.log(this.invoiceData);
      });
    // const localDataStr = localStorage.getItem('cartValues');
    const localData = localDataStr ? JSON.parse(localDataStr) : [];
    this.dataSource = new MatTableDataSource(localData);
    this.resultsLength = localData.length;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // this.userService.getTotalCount();
    // console.log(this.dataSource)
    setTimeout(() => {
      this.isLoadingData = false;
    }, 1000);
  }

  downloadFile(file: any){
    const url = this.apiUrl + '/get-pdf';
    this.http.post(url, {notification_no : file})
      .subscribe( (res: any) => {
        this.fileData = res.data;
        if(this.fileData.PDFBASE64){
          this.toastr.success('Uw bestand is gedownload');
          const linkSource = 'data:application/pdf;base64,' + this.fileData.PDFBASE64;
          const downloadLink = document.createElement("a");
          const fileName = "sample.pdf";

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
        else{
          this.toastr.error('Bestand is niet aanwezig');
        }
      });
  }

  orderPlaced: boolean = false;

  placeorder() {
    this.orderPlaced = true;
    this.isLoadingData = true;
    this.getData();
    // console.log(this.allOrders);
    const url = this.apiUrl + '/create-order';
    this.http.post(url, this.allOrders)
      .subscribe( res => {
        // console.log(res);
        if(res){
          this.isLoadingData = false;
          // @ts-ignore

          if(res.status == 0){
            // @ts-ignore
            this.toastr.error(res.message);
          }
          // @ts-ignore
          if(res.status == 1){
            // @ts-ignore
            this.toastr.success(res.message);
            localStorage.removeItem('cartValues');
            this.userService.getTotalCount();
            localStorage.setItem('orderPlacedOnce', '1');
            this.alreadyPlacedOrder = true;
            this.dataSource = new MatTableDataSource();
          }
        }

      },error => {
        // console.log(error);
        this.toastr.error(error.error.message);
        this.isLoadingData = false;
        this.orderPlaced = false;
      });
  }

  onValueChange(row: any){
    // console.log(row);
    localStorage.setItem('cartValues', JSON.stringify(this.dataSource.data));
    this.userService.getTotalCount();
    this.getData();
  }

  getData(){
    // console.log(this.dataSource.data);
    if(this.allOrders.length > 0){
      this.allOrders = [];
    }

    this.dataSource.data.forEach((value, index) => {

      const userInfo: any = JSON.parse(localStorage.getItem('user')!);
      let location_code = userInfo.Location_Code;
      const order = {
        Location_code : location_code,
        customer_email: 'customer@test.com',
        owner_email: 'owner@test.com',
        manufacturer_item_no: value.Manufacturer_Item_No,
        ean_no: value.EAN_No,
        QuantityEDE:(location_code == "BARN" ? value.quantity24 :value.inventory),
        QuantityExt: value.quantity48,
        QuantityBARN: (location_code == "BARN" ? value.inventory : value.quantity24),
        Reference: value.reference ? value.reference : null,
        Customer_No: userInfo?.No,
        customer_group: userInfo?.Customer_Price_Group,
      };
      this.allOrders.push(order);
    });

    // @ts-ignore
    // console.log(JSON.stringify(this.allOrders));

  }

  // tslint:disable-next-line:typedef
  removeField(fieldType: string, row: any){
    this.dataSource.data.forEach((value, index) => {
      if(value == row) {
        if (fieldType == 'inventory') {
          value.inventory = 0;
          // console.log(value.inventory);
        }
        if (fieldType == 'i24') {
          value.quantity24 = 0;
        }
        if (fieldType == 'i48') {
          value.quantity48 = 0;
        }
        // if (value.inventory == 0 && value.quantity24 == 0 && value.quantity48 == 0) {
        //   this.removeRow(row);
        // }

        this.getData();

      }
      return value;
    });
    localStorage.setItem('cartValues', JSON.stringify(this.dataSource.data));
    this.userService.getTotalCount();
    this.dataSource = new MatTableDataSource(this.dataSource.data);
  }

  // tslint:disable-next-line:typedef
  removeRow(row:any){
    // console.log(this.dataSource.data);
    // console.log(row);
    this.dataSource.data.forEach((value,index) => {
      if(value == row){
        this.dataSource.data.splice(index,1);
      }
    })
    localStorage.setItem('cartValues', JSON.stringify(this.dataSource.data));
    this.userService.getTotalCount();
    this.dataSource = new MatTableDataSource(this.dataSource.data);
    if(this.dataSource?.data.length == 0){
      this.alreadyPlacedOrder = true;
    }
  }

  navigateTo(){
    this.router.navigate(['banden']);
  }
}

  <div *ngIf="!idProductDetailsView">
    <div class="filters bg_white box_shadow border_radious10">
      <form class="flex-form">
        <h6 class="first_title blue">
          <label for="from">Zoeken naar banden</label>
        </h6>
        <input type="text" id="from" [(ngModel)]="zookenBanden"  [ngModelOptions]="{standalone: true}"
               placeholder="Bijv. 18560014 of 2254517"
               class="filters_first_input">

        <h6 class="second_title blue  ">
          <label for="second">Bandenmaten</label>
        </h6>
        <input type="text" [(ngModel)]="bandenmat1"   [ngModelOptions]="{standalone: true}" id="second"
               placeholder="Breedte" class="filters_second_input">
        <input type="text" [(ngModel)]="bandenmat2"   [ngModelOptions]="{standalone: true}" placeholder="Hoogte"
               class="filters_third_input">
        <input type="text" [(ngModel)]="bandenmat3"  [ngModelOptions]="{standalone: true}" placeholder="Diameter" class="filters_forth_input">
        <input type="button" (click)="filterValueZooken()" value="ZOEKEN" class="filters_button bg_red white">
      </form>
    </div>

    <!-- =================================== Drop Down Section Start =================================== -->

    <section class="drop_down bg_white box_shadow border_radious10">
      <div class="drop_down_child">
        <div class="inputWrapper">
          <mat-label><h6>Zomer/winter/all-season</h6></mat-label>

<!--          <mat-form-field class="example-form-field">-->
<!--            <input matInput type="text" placeholder="Alle">-->
<!--          </mat-form-field>-->
          <div class="drop_down_menu" (click)="toggleDropdown('zoomer')">
            <ul class="drop_down_menu_items">
              <li class="drop_down_menu_item" >
                <div class="img">
                  <img src="../../../assets/imgs/rectengle_main.svg" alt="rectengle_main">
                </div>
                <p
                  class="blue bold_font">{{zoomerSelected == 'PW' ? 'Winter' : (zoomerSelected == 'PZ' ?
                  'Zomer' : (zoomerSelected == 'PA' ? 'All-season' : 'Alle'))}}</p>

                <!-- Sub menu -->
                <ul class="sub_drop_down_menu_items bg_white" [style.display]="zomerOpen ? 'block' : 'none'">
                  <li class="sub_drop_down_menu_item" [ngClass]="{selected: zoomerSelected == zoom.value}" *ngFor="let
                zoom of zoomerOptions"
                      (click)="setValue('zoomer', zoom.value, $event); zomerOpen = false">
                    <div class="sub_img">
                      <img [src]="zoomerSelected == zoom.value ? '../../../assets/imgs/rectengle_main.svg' : '../../../assets/imgs/rectengle_big.svg'"
                           alt="rectengle_big">
                    </div>
                    <p class="blue bold_font"
                    >{{zoom.title}}</p>
                  </li>
                </ul>
              </li>
            </ul>
            <div class="icon">
              <mat-icon>arrow_drop_down</mat-icon>
            </div>
          </div>
        </div>
        <div class="inputWrapper">
          <mat-label><h6>Speed index</h6></mat-label>

<!--          <mat-form-field class="example-form-field">-->
<!--            <input matInput type="text" placeholder="Alle">-->
<!--          </mat-form-field>-->
          <div class="drop_down_menu"  (click)="toggleDropdown('si')">
            <ul class="drop_down_menu_items">
              <li class="drop_down_menu_item">
                <div class="img">
                  <img src="../../../assets/imgs/rectengle_main.svg" alt="rectengle_main">
                </div>
                <p class="blue bold_font">
                  {{siSelected}}
                  <span *ngIf="siSelectedTitle">- {{siSelectedTitle}}</span>
                </p>

                <!-- Sub menu -->
                <ul class="sub_drop_down_menu_items bg_white" [style.display]="siOpen ? 'block' : 'none'">
                  <li class="sub_drop_down_menu_item" [ngClass]="{selected: siSelected == si.value}" *ngFor="let si of
                siOptions" (click)="setValue('si', si.value, $event); siSelectedTitle = si.title; siOpen = false">
                    <div class="sub_img">
                      <img [src]="siSelected == si.value ? '../../../assets/imgs/rectengle_main.svg' :
                    '../../../assets/imgs/rectengle_big.svg'" alt="rectengle_big">
                    </div>
                    <p class="blue bold_font">{{si.value}}
                      <span *ngIf="si.title"><span *ngIf="si.value != 'ZR'">-</span>{{si.title}}</span>
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
            <div class="icon">
              <mat-icon>arrow_drop_down</mat-icon>
            </div>
          </div>
        </div>
        <div class="inputWrapper">
          <mat-label><h6>Merk</h6></mat-label>

<!--          <mat-form-field class="example-form-field">-->
<!--            <input matInput type="text" placeholder="Alle">-->
<!--          </mat-form-field>-->
          <div class="drop_down_menu" (click)="toggleDropdown('merk')">
            <ul class="drop_down_menu_items">
              <li class="drop_down_menu_item" >
                <div class="img">
                  <img src="../../../assets/imgs/rectengle_main.svg" alt="rectengle_main">
                </div>
                <p class="blue bold_font">{{merkSelected}}</p>

                <!-- Sub menu -->
                <ul class="sub_drop_down_menu_items bg_white" [style.display]="merkOpen ? 'block' : 'none'">
                  <li class="sub_drop_down_menu_item" [ngClass]="{selected: merkSelected == merk}"
                      *ngFor="let merk of merkOptions2"  (click)="setValue('merk',
                merk, $event); merkOpen = false">
                    <div class="sub_img">
                      <img [src]="merkSelected == merk ? '../../../assets/imgs/rectengle_main.svg' :
                    '../../../assets/imgs/rectengle_big.svg'" alt="rectengle_big">
                    </div>
                    <p class="blue bold_font"
                    >{{merk}}</p>
                  </li>
                </ul>
              </li>
            </ul>
            <div class="icon">
              <mat-icon>arrow_drop_down</mat-icon>
            </div>
          </div>
        </div>
        <div class="inputWrapper">
          <mat-label><h6>Soort</h6></mat-label>

<!--          <mat-form-field class="example-form-field">-->
<!--            <input matInput type="text" placeholder="Alle">-->
<!--          </mat-form-field>-->
          <div class="drop_down_menu w218" (click)="toggleDropdown('soort')">
            <ul class="drop_down_menu_items w85">
              <li class="drop_down_menu_item">
                <div class="img">
                  <img src="../../../assets/imgs/rectengle_main.svg" alt="rectengle_main">
                </div>
                <p class="blue bold_font w100">{{soortTitle}}</p>

                <!-- Sub menu -->
                <ul class="sub_drop_down_menu_items bg_white w218" [style.display]="soortOpen ? 'block' : 'none'">
                  <li class="sub_drop_down_menu_item" [ngClass]="{selected: soortSelected == soort.value}"
                      *ngFor="let soort of soortOptions2"  (click)="setValue('soort',
                soort.value, $event); soortTitle = soort.title; soortOpen = false">
                    <div class="sub_img">
                      <img [src]="soortSelected == soort.value ? '../../../assets/imgs/rectengle_main.svg' :
                    '../../../assets/imgs/rectengle_big.svg'" alt="rectengle_big">
                    </div>
                    <p class="blue bold_font"
                    >{{soort.title}}</p>
                  </li>
                </ul>
              </li>
            </ul>
            <div class="icon w7">
              <mat-icon>arrow_drop_down</mat-icon>
            </div>
          </div>
        </div>
        <div class="inputWrapper">
          <mat-label><h6>LI/SI</h6></mat-label>

<!--          <mat-form-field class="example-form-field">-->
<!--            <input matInput type="text" placeholder="Alle">-->
<!--          </mat-form-field>-->
          <input type="number" min="0" [(ngModel)]="lisiSelected"   [ngModelOptions]="{standalone: true}"
                 placeholder="LI/SI" class="lisiInput" (ngModelChange)="filterValueZooken()">
<!--          <div class="drop_down_menu" (click)="toggleDropdown('lisi')">-->
<!--            <ul class="drop_down_menu_items">-->
<!--              <li class="drop_down_menu_item" >-->
<!--                <div class="img">-->
<!--                  <img src="../../../assets/imgs/rectengle_main.svg" alt="rectengle_main">-->
<!--                </div>-->
<!--                <p class="blue bold_font">{{lisiSelected}}</p>-->

<!--                &lt;!&ndash; Sub menu &ndash;&gt;-->
<!--                <ul class="sub_drop_down_menu_items bg_white" [style.display]="lisiOpen ? 'block' : 'none'">-->
<!--                  <li class="sub_drop_down_menu_item" [ngClass]="{selected: lisiSelected == lisi.value}" *ngFor="let-->
<!--                lisi of lisiOptions" (click)="setValue('lisi',-->
<!--                lisi.value, $event)">-->
<!--                    <div class="sub_img">-->
<!--                      <img [src]="lisiSelected == lisi.value ? '../../../assets/imgs/rectengle_main.svg' :-->
<!--                    '../../../assets/imgs/rectengle_big.svg'" alt="rectengle_big">-->
<!--                    </div>-->
<!--                    <p class="blue bold_font"-->
<!--                    >{{lisi.title}}</p>-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </li>-->
<!--            </ul>-->
<!--            <div class="icon">-->
<!--              <mat-icon>arrow_drop_down</mat-icon>-->
<!--            </div>-->
<!--          </div>-->
        </div>

      </div>
    </section>

    <!--=================================== Table Section Statrt =================================== -->
    <section class="table_data bg_white box_shadow border_radious10">
      <div class="tableWrapper">
        <mat-paginator [length]="resultsLength" [pageSize]="20" (page)="handlePage($event)"></mat-paginator>
        <table *ngIf="dataSource" [style.display]="isLoadingData ? 'none' : 'table'" mat-table
               [dataSource]="dataSource"
               class="mat-elevation-z8"
               matSort>

          <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

          <!-- Position Column -->
          <ng-container matColumnDef="no">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <svg xmlns="http://www.w3.org/2000/svg" width="14.934" height="14.935" viewBox="0 0 14.934 14.935"><defs><style>.a{fill:#5a5a5a;}</style></defs><path class="a" d="M7.468,4.667a2.8,2.8,0,1,0,2.8,2.8A2.8,2.8,0,0,0,7.468,4.667Zm7.188,2.348-2.762-1.38.977-2.929a.5.5,0,0,0-.639-.639L9.3,3.045,7.92.28a.505.505,0,0,0-.9,0L5.636,3.042,2.7,2.065a.5.5,0,0,0-.639.639l.977,2.929L.28,7.015a.505.505,0,0,0,0,.9L3.042,9.3l-.977,2.932a.5.5,0,0,0,.639.639l2.929-.977,1.38,2.762a.505.505,0,0,0,.9,0L9.3,11.893l2.929.977a.5.5,0,0,0,.639-.639L11.887,9.3l2.762-1.38a.506.506,0,0,0,.006-.907Zm-4.548,3.092a3.733,3.733,0,1,1,0-5.28A3.738,3.738,0,0,1,10.107,10.107Z"/></svg>/
              <svg xmlns="http://www.w3.org/2000/svg" width="13.069" height="14.935" viewBox="0 0 13.069 14.935"><defs><style>.a{fill:#5a5a5a;}</style></defs><path class="a" d="M12.842,10.07,11.856,9.5l.758-.2a.451.451,0,0,0,.318-.551l-.117-.435a.451.451,0,0,0-.551-.318L10.2,8.547,8.335,7.468,10.2,6.391l2.065.554a.451.451,0,0,0,.551-.318l.117-.435a.451.451,0,0,0-.318-.551l-.758-.2.986-.569a.453.453,0,0,0,.166-.615l-.452-.782a.453.453,0,0,0-.615-.166l-.986.569.2-.758a.451.451,0,0,0-.318-.551L10.4,2.447a.451.451,0,0,0-.551.318L9.3,4.831,7.466,5.887V3.626L9.033,2.059a.468.468,0,0,0,0-.659l-.33-.33a.468.468,0,0,0-.659,0l-.578.575V.467A.468.468,0,0,0,7,0H6.066A.468.468,0,0,0,5.6.467V1.645l-.575-.575a.468.468,0,0,0-.659,0l-.333.33a.463.463,0,0,0,0,.659L5.6,3.626V5.887L3.767,4.831,3.213,2.765a.451.451,0,0,0-.551-.318l-.435.117a.451.451,0,0,0-.318.551l.2.758L1.127,3.3a.45.45,0,0,0-.615.166L.06,4.25a.45.45,0,0,0,.166.615l.986.569-.758.2a.452.452,0,0,0-.321.554l.117.435A.451.451,0,0,0,.8,6.945l2.065-.554L4.727,7.468,2.866,8.544.8,7.99a.451.451,0,0,0-.551.318l-.117.435a.451.451,0,0,0,.318.551l.758.2-.986.572a.453.453,0,0,0-.166.615l.452.782a.453.453,0,0,0,.615.166l.986-.569-.2.758a.451.451,0,0,0,.318.551l.435.117a.451.451,0,0,0,.551-.318l.554-2.065L5.6,9.052v2.261L4.03,12.879a.463.463,0,0,0,0,.659l.33.33a.468.468,0,0,0,.659,0l.575-.575v1.176a.468.468,0,0,0,.467.467h.933a.468.468,0,0,0,.467-.467V13.29l.575.575a.468.468,0,0,0,.659,0l.33-.33a.468.468,0,0,0,0-.659L7.466,11.309V9.049L9.3,10.1l.554,2.065a.451.451,0,0,0,.551.318l.435-.117a.451.451,0,0,0,.318-.551l-.2-.758.986.569a.45.45,0,0,0,.615-.166l.452-.782A.446.446,0,0,0,12.842,10.07Z" transform="translate(0.002)"/></svg>
            </th>
            <td mat-cell *matCellDef="let element" class="pointer">
              <svg *ngIf="element.Position_Group_Code == 'PZ' || element.Position_Group_Code == '4Z' ||
                  element.Position_Group_Code == 'PA'"
                   xmlns="http://www.w3.org/2000/svg" width="14.934"
                   height="14.935"
                   viewBox="0 0 14.934 14.935"><defs><style>.a{fill:#5a5a5a;}</style></defs><path class="a" d="M7.468,4.667a2.8,2.8,0,1,0,2.8,2.8A2.8,2.8,0,0,0,7.468,4.667Zm7.188,2.348-2.762-1.38.977-2.929a.5.5,0,0,0-.639-.639L9.3,3.045,7.92.28a.505.505,0,0,0-.9,0L5.636,3.042,2.7,2.065a.5.5,0,0,0-.639.639l.977,2.929L.28,7.015a.505.505,0,0,0,0,.9L3.042,9.3l-.977,2.932a.5.5,0,0,0,.639.639l2.929-.977,1.38,2.762a.505.505,0,0,0,.9,0L9.3,11.893l2.929.977a.5.5,0,0,0,.639-.639L11.887,9.3l2.762-1.38a.506.506,0,0,0,.006-.907Zm-4.548,3.092a3.733,3.733,0,1,1,0-5.28A3.738,3.738,0,0,1,10.107,10.107Z"/></svg>
              {{element.Position_Group_Code == 'PA' ? '/' : ''}}
              <svg *ngIf="element.Position_Group_Code == 'PW' || element.Position_Group_Code == '4W' ||
                  element.Position_Group_Code == 'PA'"
                   xmlns="http://www.w3.org/2000/svg" width="13.069"
                   height="14.935"
                   viewBox="0 0 13.069 14.935"><defs><style>.a{fill:#5a5a5a;}</style></defs><path class="a" d="M12.842,10.07,11.856,9.5l.758-.2a.451.451,0,0,0,.318-.551l-.117-.435a.451.451,0,0,0-.551-.318L10.2,8.547,8.335,7.468,10.2,6.391l2.065.554a.451.451,0,0,0,.551-.318l.117-.435a.451.451,0,0,0-.318-.551l-.758-.2.986-.569a.453.453,0,0,0,.166-.615l-.452-.782a.453.453,0,0,0-.615-.166l-.986.569.2-.758a.451.451,0,0,0-.318-.551L10.4,2.447a.451.451,0,0,0-.551.318L9.3,4.831,7.466,5.887V3.626L9.033,2.059a.468.468,0,0,0,0-.659l-.33-.33a.468.468,0,0,0-.659,0l-.578.575V.467A.468.468,0,0,0,7,0H6.066A.468.468,0,0,0,5.6.467V1.645l-.575-.575a.468.468,0,0,0-.659,0l-.333.33a.463.463,0,0,0,0,.659L5.6,3.626V5.887L3.767,4.831,3.213,2.765a.451.451,0,0,0-.551-.318l-.435.117a.451.451,0,0,0-.318.551l.2.758L1.127,3.3a.45.45,0,0,0-.615.166L.06,4.25a.45.45,0,0,0,.166.615l.986.569-.758.2a.452.452,0,0,0-.321.554l.117.435A.451.451,0,0,0,.8,6.945l2.065-.554L4.727,7.468,2.866,8.544.8,7.99a.451.451,0,0,0-.551.318l-.117.435a.451.451,0,0,0,.318.551l.758.2-.986.572a.453.453,0,0,0-.166.615l.452.782a.453.453,0,0,0,.615.166l.986-.569-.2.758a.451.451,0,0,0,.318.551l.435.117a.451.451,0,0,0,.551-.318l.554-2.065L5.6,9.052v2.261L4.03,12.879a.463.463,0,0,0,0,.659l.33.33a.468.468,0,0,0,.659,0l.575-.575v1.176a.468.468,0,0,0,.467.467h.933a.468.468,0,0,0,.467-.467V13.29l.575.575a.468.468,0,0,0,.659,0l.33-.33a.468.468,0,0,0,0-.659L7.466,11.309V9.049L9.3,10.1l.554,2.065a.451.451,0,0,0,.551.318l.435-.117a.451.451,0,0,0,.318-.551l-.2-.758.986.569a.45.45,0,0,0,.615-.166l.452-.782A.446.446,0,0,0,12.842,10.07Z" transform="translate(0.002)"/></svg>
            </td>
          </ng-container>
          <ng-container matColumnDef="fabricant">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fabrikant </th>
            <td mat-cell *matCellDef="let element" class="pointer"> {{element.Manufacturer_Name}} </td>
          </ng-container>
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
            <td mat-cell *matCellDef="let element" class="pointer"> {{element.Description}} </td>
          </ng-container>
          <ng-container matColumnDef="lisi">
            <th mat-header-cell *matHeaderCellDef> LI/SI </th>
            <td mat-cell *matCellDef="let element" class="pointer"> {{element.Description_2}} </td>
          </ng-container>
          <ng-container matColumnDef="bruto">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Bruto </th>
            <td mat-cell *matCellDef="let element" class="pointer"> &euro;{{element.Unit_Price}} </td>
          </ng-container>
          <ng-container matColumnDef="label">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Label </th>
            <td mat-cell *matCellDef="let element" class="pointer">
              <a [title]="element.Fuel_Efficiency">
                <svg width="20px" height="20px" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px"
                     viewBox="0 0 209.774 209.774" style="enable-background:new 0 0 209.774 209.774;"
                     xml:space="preserve">
                        <path
                          d="M197.575,1.312c-3.418-2.34-8.085-1.465-10.425,1.953l-16.568,24.204c-0.029,0.042-0.058,0.085-0.086,0.128l-5.491,8.388  c-3.939,6.016-6.032,12.997-6.052,20.188l-0.294,105.754c-0.005,1.73-1.414,3.134-3.146,3.134c-0.003,0-0.006,0-0.009,0l-0.211,0  c-1.133-0.003-1.877-0.582-2.22-0.927s-0.917-1.092-0.914-2.225l0.168-60.271c0.033-12.017-9.518-21.86-21.438-22.29l0.12-43.019  c0.015-5.298-2.034-10.284-5.77-14.041c-3.735-3.756-8.71-5.833-14.008-5.848L29.175,16.21c-10.906,0-19.803,8.873-19.833,19.778  L8.935,181.987c-0.019,6.907,4.822,12.704,11.294,14.173l-0.016,5.859c-0.011,4.142,3.337,7.509,7.479,7.521l84,0.234  c0.007,0,0.014,0,0.021,0c1.981,0,3.883-0.784,5.289-2.182c1.411-1.402,2.206-3.308,2.211-5.297l0.016-5.859  c6.477-1.434,11.353-7.211,11.372-14.11l0.245-87.96c1.652,0.173,3.183,0.902,4.371,2.096c1.366,1.373,2.115,3.196,2.109,5.132  l-0.168,60.271c-0.014,4.846,1.861,9.407,5.278,12.844c3.417,3.436,7.968,5.336,12.818,5.35l0.211,0c0.017,0,0.033,0,0.05,0  c9.98,0,18.114-8.105,18.143-18.092l0.294-105.755c0.005-1.801,0.241-3.576,0.673-5.299c2.438,1.564,5.325,2.488,8.43,2.497  c0.007,0,0.039-0.004,0.044,0c8.66,0,15.725-7.046,15.75-15.706c0.012-4.207-1.616-8.167-4.582-11.15  c-0.966-0.971-2.045-1.786-3.196-2.464l8.456-12.353C201.867,8.319,200.993,3.651,197.575,1.312z M103.653,43.876l-0.089,32  L36.897,75.69l0.089-32L103.653,43.876z" />
                  <circle cx="183.1" cy="37.66" r="8.25" />

                    </svg>
              </a>
<!--              {{element.Fuel_Efficiency}}-->
              <a [title]="element.Wet_Grip">
                <svg xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px"
                     width="20px" height="20px" viewBox="0 0 264.564 264.564"
                     style="enable-background:new 0 0 264.564 264.564;" xml:space="preserve">
                        <g>
                            <g>
                                <path
                                  d="M132.281,264.564c51.24,0,92.931-41.681,92.931-92.918c0-50.18-87.094-164.069-90.803-168.891L132.281,0l-2.128,2.773    c-3.704,4.813-90.802,118.71-90.802,168.882C39.352,222.883,81.042,264.564,132.281,264.564z" />
                            </g>
                        </g>

                    </svg>
              </a>
<!--              {{element.Wet_Grip}}-->
              <a [title]="element.Noise_Class_Type">
                <svg  xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="20px" height="20px"
                      id="Layer_1"
                      x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;"
                      xml:space="preserve">
                        <g>
                            <g>
                                <path d="M240.419,42.298l-129.114,96.836H16.696C7.475,139.133,0,146.608,0,155.829v200.35c0,9.221,7.475,16.696,16.696,16.696    h94.609l129.114,96.836c10.92,8.19,26.713,0.47,26.713-13.357V55.654C267.133,41.928,251.415,34.051,240.419,42.298z     M100.175,339.483H33.392V172.525h66.783V339.483z M233.741,422.962l-100.175-75.131V164.177l100.175-75.131V422.962z" />
                            </g>
                        </g>
                  <g>
                            <g>
                                <path
                                  d="M438.761,78.919c-6.519-6.52-17.091-6.52-23.611,0c-6.52,6.52-6.52,17.091,0,23.611    c40.92,40.92,63.457,95.423,63.458,153.47c0.001,58.048-22.535,112.555-63.458,153.478c-6.52,6.52-6.52,17.091,0,23.611    c3.26,3.26,7.533,4.891,11.806,4.891s8.546-1.63,11.805-4.891c47.23-47.23,73.24-110.122,73.239-177.09    C511.999,189.034,485.989,126.145,438.761,78.919z" />
                            </g>
                        </g>
                  <g>
                            <g>
                                <path
                                  d="M391.545,126.124c-6.52-6.523-17.092-6.524-23.611-0.006c-6.521,6.518-6.525,17.09-0.007,23.611    c58.571,58.6,58.571,153.949,0,212.549c-6.518,6.521-6.516,17.093,0.007,23.611c3.26,3.259,7.531,4.887,11.803,4.887    c4.274,0,8.549-1.632,11.808-4.893C463.126,314.268,463.126,197.74,391.545,126.124z" />
                            </g>
                        </g>
                  <g>
                            <g>
                                <path
                                  d="M344.342,173.373c-6.518-6.522-17.09-6.522-23.611-0.004c-6.521,6.519-6.524,17.09-0.004,23.611    c32.532,32.546,32.517,85.516-0.032,118.079c-6.518,6.523-6.516,17.093,0.006,23.612c3.26,3.259,7.532,4.887,11.803,4.887    c4.274,0,8.548-1.631,11.807-4.893C389.871,293.085,389.886,218.936,344.342,173.373z" />
                            </g>
                        </g>

                    </svg>
              </a>
<!--              {{element.Noise_Class_Type}}-->
              <a *ngIf="element.V3PMSF_Mark" class="snow">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                     width="20px" height="20px" viewBox="0 0 51.000000 51.000000"
                     preserveAspectRatio="xMidYMid meet">

                  <g transform="translate(0.000000,51.000000) scale(0.100000,-0.100000)"
                     fill="#000000">
                    <path d="M175 363 c-15 -32 -34 -76 -42 -98 -21 -51 -20 -50 -37 -36 -12 10
-17 7 -29 -16 -31 -63 -58 -143 -52 -152 8 -14 391 -15 399 -2 3 5 -25 69 -63
143 -49 96 -73 133 -86 131 -11 -1 -23 14 -35 43 -11 24 -21 44 -24 44 -2 0
-16 -26 -31 -57z m55 -19 c17 -38 23 -44 35 -34 11 10 24 -9 74 -111 34 -67
59 -124 57 -126 -2 -2 -87 -2 -189 -1 l-187 3 13 25 c7 14 21 47 31 73 15 40
20 46 32 36 18 -15 26 -7 47 46 26 68 58 135 63 135 3 0 14 -21 24 -46z"/>
                    <path d="M175 239 c-4 -6 1 -16 11 -24 23 -16 25 -15 17 13 -6 24 -18 28 -28
11z"/>
                    <path d="M237 232 c6 -25 -41 -56 -72 -48 -19 5 -23 2 -22 -14 2 -24 2 -24 27
-8 16 10 22 10 32 0 11 -11 9 -17 -9 -31 -16 -14 -19 -21 -10 -30 18 -18 27
-13 27 13 0 14 4 27 9 30 12 8 34 -21 26 -34 -3 -6 3 -10 14 -10 27 0 27 17
-1 40 -21 17 -22 19 -5 26 10 3 23 2 30 -5 16 -16 26 -14 22 7 -2 9 -4 21 -4
26 -1 5 -8 3 -16 -4 -8 -7 -23 -9 -32 -5 -17 6 -16 8 3 23 24 20 21 42 -6 42
-13 0 -17 -6 -13 -18z"/>
                  </g>
                </svg>
              </a>
              <a *ngIf="element.Special_Compound == 'ICE'" class="snow">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                     width="20px" height="20px" viewBox="0 0 57.000000 51.000000"
                     preserveAspectRatio="xMidYMid meet">

                  <g transform="translate(0.000000,51.000000) scale(0.100000,-0.100000)"
                     fill="#000000">
                    <path d="M247 463 c-3 -10 -26 -65 -52 -123 -38 -86 -93 -211 -122 -278 -4 -9
41 -12 207 -12 116 0 210 3 208 8 -1 4 -33 66 -70 137 -37 72 -85 163 -106
204 -40 78 -56 94 -65 64z m127 -207 c53 -103 96 -190 96 -192 0 -2 -85 -4
-190 -4 -104 0 -190 2 -190 5 0 3 25 61 55 130 30 68 55 126 55 128 0 18 64
137 70 130 4 -4 51 -93 104 -197z"/>
                    <path d="M316 283 c-3 -3 -6 -17 -6 -31 0 -17 -4 -23 -15 -18 -10 3 -15 -1
-15 -12 0 -9 -4 -23 -9 -31 -7 -11 -12 -4 -22 27 -7 26 -16 39 -23 34 -6 -3
-17 -7 -23 -7 -12 0 -83 -135 -83 -156 0 -5 69 -9 160 -9 88 0 160 2 160 4 0
8 -102 196 -109 201 -5 2 -11 2 -15 -2z m62 -104 c23 -44 42 -83 42 -85 0 -2
-63 -4 -140 -4 -77 0 -140 3 -140 8 0 4 12 35 27 69 33 76 57 80 83 13 17 -45
23 -44 34 3 4 15 11 25 16 22 6 -4 13 7 16 24 4 17 10 31 13 31 4 0 26 -36 49
-81z"/>
                  </g>
                </svg>
              </a>
            </td>
          </ng-container>
            <ng-container matColumnDef="vestiging">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Vestiging</th>
              <td mat-cell *matCellDef="let element" class="pointer" [ngClass]="element.calculated_inventory > 3 ? 'greenFont' : 'blueFont'">
                <ng-container>&euro;{{element.Unit_Price}} {{element.calculated_inventory <= 3 ? "(" + element.calculated_inventory + ")" : '' }}</ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="uurs">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 4 uur </th>
              <td mat-cell *matCellDef="let element" class="pointer" [ngClass]="element.calculated_quantity24 > 3 ? 'greenFont' : 'blueFont'">
                <ng-container [ngClass]="{}">&euro;{{element.Price24}} {{element.calculated_quantity24 <= 3 ? "(" + element.calculated_quantity24 + ")" : ''}}</ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="uur">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 24 uur</th>
              <td mat-cell *matCellDef="let element" class="pointer" [ngClass]="element.calculated_quantity48 > 3 ? 'greenFont' : 'blueFont'">
                <ng-container [ngClass]="{}">&euro;{{element.Price48}} {{element.calculated_quantity48 <= 3 ? "(" + element.calculated_quantity48 + ")" : '' }}</ng-container>
              </td>
            </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openProduct(row)"></tr>
        </table>

        <div class="spinnerWrapper" *ngIf="isLoadingData">
          <div class="spinner"></div>
        </div>
        <!-- <mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator> -->
      </div>
      <div class="tableWrapper noData" *ngIf="resultsLength == 0">
        <span>No products available</span>
      </div>

    </section>
  </div>

  <div *ngIf="idProductDetailsView">
    <section class="rim_tab">
      <div class="rim_tab_child">
        <div class="left_good_year_detail_div bg_white border_radious10 box_shadow">
          <h5 class="blue">{{productData.Manufacturer_Name}}</h5>
          <div class="detail_table">
            <table>
              <tbody>
              <tr>
                <td class="title grey bold_font">Type</td>
                <td class="grey">{{productData.Description}}</td>
              </tr>
              <tr>
                <td class="title grey bold_font">Breedte</td>
                <td class="grey">{{productData.Width}}</td>
              </tr>
              <tr>
                <td class="title grey bold_font">Hoogte</td>
                <td class="grey">{{productData.Aspect_Ratio}}</td>
              </tr>
              <tr>
                <td class="title grey bold_font">Diameter</td>
                <td class="grey">{{productData.Diameter}}</td>
              </tr>
              <tr>
                <td class="title grey bold_font">Profiel</td>
                <td class="grey">{{productData.Manufacturer_Code}}</td>
              </tr>
              <tr>
                <td class="title grey bold_font">Loadindex</td>
                <td class="grey">{{productData.Loadindex_1_single_tire}}</td>
              </tr>
              <tr>
                <td class="title grey bold_font">Snelheidsindex</td>
                <td class="grey">{{productData.Speedindex}}</td>
              </tr>
              <tr>
                <td class="title grey bold_font">EAN number</td>
                <td class="grey">{{productData.EAN_No}}</td>
              </tr>
              <tr>
                <td class="title grey bold_font">Bruto</td>
                <td class="grey">€{{productData.Unit_Price}}</td>
              </tr>
              <tr>
                <td class="title grey bold_font">Label</td>
                <td>
                  <span class="icon_parent">
<!--                                    <mat-icon>volume_up</mat-icon>-->
                    <svg width="20px" height="20px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 209.774 209.774" xml:space="preserve" style="enable-background: new 0 0 209.774 209.774;"><path _ngcontent-pcm-c132="" d="M197.575,1.312c-3.418-2.34-8.085-1.465-10.425,1.953l-16.568,24.204c-0.029,0.042-0.058,0.085-0.086,0.128l-5.491,8.388  c-3.939,6.016-6.032,12.997-6.052,20.188l-0.294,105.754c-0.005,1.73-1.414,3.134-3.146,3.134c-0.003,0-0.006,0-0.009,0l-0.211,0  c-1.133-0.003-1.877-0.582-2.22-0.927s-0.917-1.092-0.914-2.225l0.168-60.271c0.033-12.017-9.518-21.86-21.438-22.29l0.12-43.019  c0.015-5.298-2.034-10.284-5.77-14.041c-3.735-3.756-8.71-5.833-14.008-5.848L29.175,16.21c-10.906,0-19.803,8.873-19.833,19.778  L8.935,181.987c-0.019,6.907,4.822,12.704,11.294,14.173l-0.016,5.859c-0.011,4.142,3.337,7.509,7.479,7.521l84,0.234  c0.007,0,0.014,0,0.021,0c1.981,0,3.883-0.784,5.289-2.182c1.411-1.402,2.206-3.308,2.211-5.297l0.016-5.859  c6.477-1.434,11.353-7.211,11.372-14.11l0.245-87.96c1.652,0.173,3.183,0.902,4.371,2.096c1.366,1.373,2.115,3.196,2.109,5.132  l-0.168,60.271c-0.014,4.846,1.861,9.407,5.278,12.844c3.417,3.436,7.968,5.336,12.818,5.35l0.211,0c0.017,0,0.033,0,0.05,0  c9.98,0,18.114-8.105,18.143-18.092l0.294-105.755c0.005-1.801,0.241-3.576,0.673-5.299c2.438,1.564,5.325,2.488,8.43,2.497  c0.007,0,0.039-0.004,0.044,0c8.66,0,15.725-7.046,15.75-15.706c0.012-4.207-1.616-8.167-4.582-11.15  c-0.966-0.971-2.045-1.786-3.196-2.464l8.456-12.353C201.867,8.319,200.993,3.651,197.575,1.312z M103.653,43.876l-0.089,32  L36.897,75.69l0.089-32L103.653,43.876z"></path><circle _ngcontent-pcm-c132="" cx="183.1" cy="37.66" r="8.25"></circle></svg>
                      <span class="light_font grey">{{productData.Fuel_Efficiency}}</span>
                  </span>
                  <span class="icon_parent">
<!--                                    <mat-icon>volume_up</mat-icon>-->
                      <svg  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 264.564 264.564" xml:space="preserve" style="enable-background: new 0 0 264.564 264.564;"><g _ngcontent-sgg-c132=""><g _ngcontent-sgg-c132=""><path _ngcontent-sgg-c132="" d="M132.281,264.564c51.24,0,92.931-41.681,92.931-92.918c0-50.18-87.094-164.069-90.803-168.891L132.281,0l-2.128,2.773    c-3.704,4.813-90.802,118.71-90.802,168.882C39.352,222.883,81.042,264.564,132.281,264.564z"></path></g></g></svg>
                      <span class="light_font grey">{{productData.Wet_Grip}}</span>
                  </span>
                  <span class="icon_parent">
<!--                                    <mat-icon>volume_up</mat-icon>-->
                      <svg  xmlns="http://www.w3.org/2000/svg"
                           xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="20px" height="20px" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve" style="enable-background: new 0 0 512 512;"><g _ngcontent-loa-c132=""><g _ngcontent-loa-c132=""><path _ngcontent-loa-c132="" d="M240.419,42.298l-129.114,96.836H16.696C7.475,139.133,0,146.608,0,155.829v200.35c0,9.221,7.475,16.696,16.696,16.696    h94.609l129.114,96.836c10.92,8.19,26.713,0.47,26.713-13.357V55.654C267.133,41.928,251.415,34.051,240.419,42.298z     M100.175,339.483H33.392V172.525h66.783V339.483z M233.741,422.962l-100.175-75.131V164.177l100.175-75.131V422.962z"></path></g></g><g _ngcontent-loa-c132=""><g _ngcontent-loa-c132=""><path _ngcontent-loa-c132="" d="M438.761,78.919c-6.519-6.52-17.091-6.52-23.611,0c-6.52,6.52-6.52,17.091,0,23.611    c40.92,40.92,63.457,95.423,63.458,153.47c0.001,58.048-22.535,112.555-63.458,153.478c-6.52,6.52-6.52,17.091,0,23.611    c3.26,3.26,7.533,4.891,11.806,4.891s8.546-1.63,11.805-4.891c47.23-47.23,73.24-110.122,73.239-177.09    C511.999,189.034,485.989,126.145,438.761,78.919z"></path></g></g><g _ngcontent-loa-c132=""><g _ngcontent-loa-c132=""><path _ngcontent-loa-c132="" d="M391.545,126.124c-6.52-6.523-17.092-6.524-23.611-0.006c-6.521,6.518-6.525,17.09-0.007,23.611    c58.571,58.6,58.571,153.949,0,212.549c-6.518,6.521-6.516,17.093,0.007,23.611c3.26,3.259,7.531,4.887,11.803,4.887    c4.274,0,8.549-1.632,11.808-4.893C463.126,314.268,463.126,197.74,391.545,126.124z"></path></g></g><g _ngcontent-loa-c132=""><g _ngcontent-loa-c132=""><path _ngcontent-loa-c132="" d="M344.342,173.373c-6.518-6.522-17.09-6.522-23.611-0.004c-6.521,6.519-6.524,17.09-0.004,23.611    c32.532,32.546,32.517,85.516-0.032,118.079c-6.518,6.523-6.516,17.093,0.006,23.612c3.26,3.259,7.532,4.887,11.803,4.887    c4.274,0,8.548-1.631,11.807-4.893C389.871,293.085,389.886,218.936,344.342,173.373z"></path></g></g></svg>
                      <span class="light_font grey">{{productData.Noise_Class_Type}}</span>
                  </span>
                  <span *ngIf="productData.V3PMSF_Mark" class="icon_parent snow">
<!--                                    <mat-icon>volume_up</mat-icon>-->
                      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                           width="30px" height="30px" viewBox="0 0 51.000000 51.000000"
                           preserveAspectRatio="xMidYMid meet">

                  <g transform="translate(0.000000,51.000000) scale(0.100000,-0.100000)"
                     fill="#000000">
                    <path d="M175 363 c-15 -32 -34 -76 -42 -98 -21 -51 -20 -50 -37 -36 -12 10
-17 7 -29 -16 -31 -63 -58 -143 -52 -152 8 -14 391 -15 399 -2 3 5 -25 69 -63
143 -49 96 -73 133 -86 131 -11 -1 -23 14 -35 43 -11 24 -21 44 -24 44 -2 0
-16 -26 -31 -57z m55 -19 c17 -38 23 -44 35 -34 11 10 24 -9 74 -111 34 -67
59 -124 57 -126 -2 -2 -87 -2 -189 -1 l-187 3 13 25 c7 14 21 47 31 73 15 40
20 46 32 36 18 -15 26 -7 47 46 26 68 58 135 63 135 3 0 14 -21 24 -46z"/>
                    <path d="M175 239 c-4 -6 1 -16 11 -24 23 -16 25 -15 17 13 -6 24 -18 28 -28
11z"/>
                    <path d="M237 232 c6 -25 -41 -56 -72 -48 -19 5 -23 2 -22 -14 2 -24 2 -24 27
-8 16 10 22 10 32 0 11 -11 9 -17 -9 -31 -16 -14 -19 -21 -10 -30 18 -18 27
-13 27 13 0 14 4 27 9 30 12 8 34 -21 26 -34 -3 -6 3 -10 14 -10 27 0 27 17
-1 40 -21 17 -22 19 -5 26 10 3 23 2 30 -5 16 -16 26 -14 22 7 -2 9 -4 21 -4
26 -1 5 -8 3 -16 -4 -8 -7 -23 -9 -32 -5 -17 6 -16 8 3 23 24 20 21 42 -6 42
-13 0 -17 -6 -13 -18z"/>
                  </g>
                </svg>
                  </span>
                  <span *ngIf="productData.Special_Compound == 'ICE'" class="icon_parent snow">
<!--                                    <mat-icon>volume_up</mat-icon>-->
                       <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="20px" height="20px" viewBox="0 0 57.000000 51.000000"
                            preserveAspectRatio="xMidYMid meet">

                  <g transform="translate(0.000000,51.000000) scale(0.100000,-0.100000)"
                     fill="#000000">
                    <path d="M247 463 c-3 -10 -26 -65 -52 -123 -38 -86 -93 -211 -122 -278 -4 -9
41 -12 207 -12 116 0 210 3 208 8 -1 4 -33 66 -70 137 -37 72 -85 163 -106
204 -40 78 -56 94 -65 64z m127 -207 c53 -103 96 -190 96 -192 0 -2 -85 -4
-190 -4 -104 0 -190 2 -190 5 0 3 25 61 55 130 30 68 55 126 55 128 0 18 64
137 70 130 4 -4 51 -93 104 -197z"/>
                    <path d="M316 283 c-3 -3 -6 -17 -6 -31 0 -17 -4 -23 -15 -18 -10 3 -15 -1
-15 -12 0 -9 -4 -23 -9 -31 -7 -11 -12 -4 -22 27 -7 26 -16 39 -23 34 -6 -3
-17 -7 -23 -7 -12 0 -83 -135 -83 -156 0 -5 69 -9 160 -9 88 0 160 2 160 4 0
8 -102 196 -109 201 -5 2 -11 2 -15 -2z m62 -104 c23 -44 42 -83 42 -85 0 -2
-63 -4 -140 -4 -77 0 -140 3 -140 8 0 4 12 35 27 69 33 76 57 80 83 13 17 -45
23 -44 34 3 4 15 11 25 16 22 6 -4 13 7 16 24 4 17 10 31 13 31 4 0 26 -36 49
-81z"/>
                  </g>
                </svg>
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="right_div bg_red border_radious10 box_shadow">
          <div class="detail_table white">
            <table>
              <tbody>
              <tr>
                <td class="title bold_font">Vestiging</td>
                <td class="light_font text_right">€{{productData.Unit_Price}}</td>
                <td class="input text_center">
                  <input type="number" [(ngModel)]="orderInfo.price" min="0" id="vestiging2" name="vestiging">
                  <!--                <a href="javascript:void(0)" (click)="addToCart('price')"-->
                  <!--                   class="shopping_cart_logo display_inline">-->
                  <!--                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"-->
                  <!--                       width="902.86px" height="902.86px" viewBox="0 0 902.86 902.86" style="enable-background:new 0 0 902.86 902.86;"-->
                  <!--                       xml:space="preserve">-->
                  <!--                                        <g>-->
                  <!--                                          <g>-->
                  <!--                                            <path d="M671.504,577.829l110.485-432.609H902.86v-68H729.174L703.128,179.2L0,178.697l74.753,399.129h596.751V577.829z-->
                  <!--                                              M685.766,247.188l-67.077,262.64H131.199L81.928,246.756L685.766,247.188z"/>-->
                  <!--                                            <path d="M578.418,825.641c59.961,0,108.743-48.783,108.743-108.744s-48.782-108.742-108.743-108.742H168.717-->
                  <!--                                              c-59.961,0-108.744,48.781-108.744,108.742s48.782,108.744,108.744,108.744c59.962,0,108.743-48.783,108.743-108.744-->
                  <!--                                              c0-14.4-2.821-28.152-7.927-40.742h208.069c-5.107,12.59-7.928,26.342-7.928,40.742-->
                  <!--                                              C469.675,776.858,518.457,825.641,578.418,825.641z M209.46,716.897c0,22.467-18.277,40.744-40.743,40.744-->
                  <!--                                              c-22.466,0-40.744-18.277-40.744-40.744c0-22.465,18.277-40.742,40.744-40.742C191.183,676.155,209.46,694.432,209.46,716.897z-->
                  <!--                                              M619.162,716.897c0,22.467-18.277,40.744-40.743,40.744s-40.743-18.277-40.743-40.744c0-22.465,18.277-40.742,40.743-40.742-->
                  <!--                                              S619.162,694.432,619.162,716.897z"/>-->
                  <!--                                          </g>-->
                  <!--                                        </g>-->
                  <!--                                    </svg>-->
                  <!--                </a>-->
                </td>
                <td>{{orderInfo.calculated_inventory}}</td>
              </tr>
              <tr>
                <td class="title bold_font">4 uur</td>
                <td class="light_font text_right">€{{productData.Price24}}</td>
                <td class="input text_center">
                  <input type="number" id="vestiging4"  min="0" [(ngModel)]="orderInfo.i24"  name="vestiging">
                  <!--                <a href="javascript:void(0)" (click)="addToCart('i24')"-->
                  <!--                   class="shopping_cart_logo display_inline">-->
                  <!--                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"-->
                  <!--                       width="902.86px" height="902.86px" viewBox="0 0 902.86 902.86" style="enable-background:new 0 0 902.86 902.86;"-->
                  <!--                       xml:space="preserve">-->
                  <!--                                        <g>-->
                  <!--                                          <g>-->
                  <!--                                            <path d="M671.504,577.829l110.485-432.609H902.86v-68H729.174L703.128,179.2L0,178.697l74.753,399.129h596.751V577.829z-->
                  <!--                                              M685.766,247.188l-67.077,262.64H131.199L81.928,246.756L685.766,247.188z"/>-->
                  <!--                                            <path d="M578.418,825.641c59.961,0,108.743-48.783,108.743-108.744s-48.782-108.742-108.743-108.742H168.717-->
                  <!--                                              c-59.961,0-108.744,48.781-108.744,108.742s48.782,108.744,108.744,108.744c59.962,0,108.743-48.783,108.743-108.744-->
                  <!--                                              c0-14.4-2.821-28.152-7.927-40.742h208.069c-5.107,12.59-7.928,26.342-7.928,40.742-->
                  <!--                                              C469.675,776.858,518.457,825.641,578.418,825.641z M209.46,716.897c0,22.467-18.277,40.744-40.743,40.744-->
                  <!--                                              c-22.466,0-40.744-18.277-40.744-40.744c0-22.465,18.277-40.742,40.744-40.742C191.183,676.155,209.46,694.432,209.46,716.897z-->
                  <!--                                              M619.162,716.897c0,22.467-18.277,40.744-40.743,40.744s-40.743-18.277-40.743-40.744c0-22.465,18.277-40.742,40.743-40.742-->
                  <!--                                              S619.162,694.432,619.162,716.897z"/>-->
                  <!--                                          </g>-->
                  <!--                                        </g>-->
                  <!--                                    </svg>-->
                  <!--                </a>-->
                </td>
                <td>{{orderInfo.calculated_quantity24}}</td>
              </tr>
              <tr>
                <td class="title bold_font">24 uur</td>
                <td class="light_font text_right">€{{productData.Price48}}</td>
                <td class="input text_center">
                  <input type="number" id="vestiging3"  min="0" [(ngModel)]="orderInfo.i48"  name="vestiging">
                </td>
                <td>{{orderInfo.calculated_quantity48}}</td>
              </tr>
              <a href="javascript:void(0)" 
                 class="shopping_cart_logo display_inline">
                <svg (click)="addToCart('price', 'i24', 'i48')" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     width="20px" height="20px" viewBox="0 0 902.86 902.86"
                     style="enable-background:new 0 0 902.86 902.86; color: white; fill: currentColor"
                     xml:space="preserve">
                    <g>
                      <g>
                        <path d="M671.504,577.829l110.485-432.609H902.86v-68H729.174L703.128,179.2L0,178.697l74.753,399.129h596.751V577.829z
                          M685.766,247.188l-67.077,262.64H131.199L81.928,246.756L685.766,247.188z"/>
                        <path d="M578.418,825.641c59.961,0,108.743-48.783,108.743-108.744s-48.782-108.742-108.743-108.742H168.717
                          c-59.961,0-108.744,48.781-108.744,108.742s48.782,108.744,108.744,108.744c59.962,0,108.743-48.783,108.743-108.744
                          c0-14.4-2.821-28.152-7.927-40.742h208.069c-5.107,12.59-7.928,26.342-7.928,40.742
                          C469.675,776.858,518.457,825.641,578.418,825.641z M209.46,716.897c0,22.467-18.277,40.744-40.743,40.744
                          c-22.466,0-40.744-18.277-40.744-40.744c0-22.465,18.277-40.742,40.744-40.742C191.183,676.155,209.46,694.432,209.46,716.897z
                          M619.162,716.897c0,22.467-18.277,40.744-40.743,40.744s-40.743-18.277-40.743-40.744c0-22.465,18.277-40.742,40.743-40.742
                          S619.162,694.432,619.162,716.897z"/>
                      </g>
                    </g>
                </svg>
              </a>
              </tbody>

            </table>
          </div>
        </div>
        <div class="back_button">
          <i class="fa fa-chevron-left" (click)="backButton()"></i>
        </div>
      </div>
    </section>
  </div>
  <app-product-box>

  </app-product-box>



<!-- =================================== App Product BOx =================================== -->



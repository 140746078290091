import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../environments/environment";
import {BehaviorSubject, Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  homeContent: any;
  velgenContent: any;
  productContent: any;
  apiUrl = '';
  content: any;
  userId: any;
  fileContent: any;
  // @ts-ignore
  fileData = new Subject<any>();
  fileDataObservable = this.fileData.asObservable();

  constructor(public http: HttpClient) { }

  getFileDetails(){

  }

  // tslint:disable-next-line:typedef
  getHomeContent(){
    this.apiUrl = environment.url + '/get-home-page-content';
    return this.http.post(this.apiUrl, null);
      // .subscribe(res => {
      //   this.homeContent = res;
      // });
  }

  // tslint:disable-next-line:typedef
  getVelgenContent(){
    this.apiUrl = environment.url + '/get-velgen-page-content';
    return this.http.post(this.apiUrl, null);
    // return this.velgenContent;
  }

  getProductContent(){
    this.apiUrl = environment.url;
    const url = this.apiUrl + '/get-home-page-content';
    return this.http.post(url, null);
  }


}

import {AfterViewInit, Component, HostListener, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {HttpClient, HttpClientModule, HttpHeaders} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ToastrService} from 'ngx-toastr';
import {UserService} from "../../services/user.service";
import {environment} from "../../../environments/environment";
import {Router} from "@angular/router";

@Component({
  selector: 'app-wheel-tab',
  templateUrl: './wheel-tab.component.html',
  styleUrls: ['./wheel-tab.component.scss']
})

export class WheelTabComponent implements OnInit, AfterViewInit, OnChanges {
  displayedColumns: string[] = ['no', 'fabricant', 'type', 'lisi', 'bruto', 'label', 'vestiging', 'uurs', 'uur'];

  dataSource!: MatTableDataSource<any>;
  apiUrl: string = ''
  resultsLength = 0;
  zookenBanden: any = '';
  bandenmat1: any = '';
  bandenmat2: any = '';
  bandenmat3: any = '';
  zomerOpen = false;
  siOpen = false;
  isFilterApplied = false;
  zoomerSelected: any = 'Alle';
  siSelected: any = 'Alle';
  zoomerOptions: any = [
    {
      value: 'Alle',
      title: 'Alle',
    },
    {
      value: 'PZ',
      title: 'Zomer',
    },
    {
      value: 'PW',
      title: 'Winter'
    }, {
      value: 'PA',
      title: 'All-season',
    }
  ];
  siOptions: any = [
    {
      value: 'Alle',
      title: '',
    },
    {
      value: 'L',
      title: '120',
    },
    {
      value: 'L',
      title: '120',
    },
    {
      value: 'M',
      title: '130',
    },
    {
      value: 'N',
      title: '140',
    },
    {
      value: 'P',
      title: '150',
    },
    {
      value: 'Q',
      title: '160',
    },
    {
      value: 'R',
      title: '170',
    },
    {
      value: 'S',
      title: '180',
    },
    {
      value: 'T',
      title: '190',
    },
    {
      value: 'H',
      title: '210',
    },
    {
      value: 'V',
      title: '240',
    },
    {
      value: 'W(ZR)',
      title: '270',
    },
    {
      value: 'Y(ZR)',
      title: '300',
    },
    {
      value: 'ZR',
      title: '>240',
    }
  ];
  siSelectedTitle:string = '';
  merkOpen = false;
  merkSelected: any = 'Alle';
  merkOptions: any = [
    {
      value: 'Alle',
      title: 'Alle',
    },
    {
      value: 'Cislaved',
      title: 'Cislaved',
    },
    {
      value: 'Michelin',
      title: 'Michelin',
    },
    {
      value: 'Continental',
      title: 'Continental',
    },

  ];
  merkOptions2:any = [];
  soortOpen = false;
  soortSelected: any = 'Alle';
  soortOptions: any = [
    {
      value: 'Alle',
      title: 'Alle',
    },
    {
      value: 'Bestel',
      title: 'Bestel',
    },
    {
      value: 'Personen',
      title: 'Personen',
    },
    {
      value: 'Runflat',
      title: 'Runflat',
    },
    {
      value: 'SUV/4x4',
      title: 'SUV/4x4',
    },

  ];
  lisiOpen = false;
  isLoadingData = false;
  lisiSelected: number = 0;
  lisiOptions: any = [
    {
      value: 'Alle',
      title: 'Alle',
    },
    {
      value: '75T TL',
      title: '75T TL',
    },
    {
      value: '91T TL',
      title: '91T TL',
    },
    {
      value: '86G TL XL',
      title: '86G TL XL',
    },
    {
      value: '102/100 R T',
      title: '102/100 R T',
    }
  ];
  idProductDetailsView = false;
  productData: any;
  orderInfo: { [key: string]: any } = {
    price: null,
    i24: null,
    i48: null,
    calculated_inventory: null,
    calculated_quantity24: null,
    calculated_quantity48: null
  };
  soortOptions2: any[] = [];
  tabSwitchedDone: boolean = false;
  showAccountTab: boolean = false;
  currentStatus:number = 0;
  soortTitle:string = 'Alle';
  merkTitle:string = 'Alle';
  userInfo: any ;
  @Input() switching: any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  // @ViewChild(MatSort) sort!: MatSort;
  // tslint:disable-next-line:typedef
  @HostListener('document:click') clickout() {
    this.zomerOpen = false;
    this.soortOpen = false;
    this.merkOpen = false;
    this.lisiOpen = false;
    this.siOpen = false;
  }
  constructor(private http: HttpClient, private toastr: ToastrService,
              // tslint:disable-next-line:variable-name
              private _snackBar: MatSnackBar,
              public httpClient: HttpClient,
              public userService: UserService,
              public router: Router) {

  }
  // tslint:disable-next-line:typedef
  filterValueZooken() {
    this.isFilterApplied = true;
    this.getFilteredData();
  }
  // tslint:disable-next-line:typedef
  ngAfterViewInit() {

  }

  ngOnChanges(): void {
    this.idProductDetailsView = false;
    this.orderInfo = {
      price: 0,
      i24: 0,
      i48: 0,
      calculated_inventory: 0,
      calculated_quantity24: 0,
      calculated_quantity48: 0
    };
    setTimeout( () => {
      this.paginator.pageIndex = this.currentStatus;
      this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;
    }, 500);
  }
  // tslint:disable-next-line:typedef
  addToCart(type1: string, type2: string, type3: string) {
    // console.log('add cart');
    const localDataStr = localStorage.getItem('cartValues');
    const localData = localDataStr ? JSON.parse(localDataStr) : [];
    let flag = true;
    if (!this.orderInfo[type1.toString()] && !this.orderInfo[type2.toString()] && !this.orderInfo[type3.toString()]) {
      this.toastr.error('Voeg eerst wat waarde toe');
      return;
    }
    if (this.orderInfo[type1.toString()] <= 0
      && this.orderInfo[type2.toString()] <= 0
      && this.orderInfo[type3.toString()] <= 0) {
      this.toastr.error('Voeg geldige waarden toe');
      return;
    }
    if (this.orderInfo[type1.toString()]  > this.orderInfo.calculated_inventory
    || this.orderInfo[type2.toString()]  > this.orderInfo.calculated_quantity24
    || this.orderInfo[type3.toString()]  > this.orderInfo.calculated_quantity48) {
    // Vest, 4uur and 24uur is more 
    if (this.orderInfo[type1.toString()]  > this.orderInfo.calculated_inventory
    && this.orderInfo[type2.toString()]  > this.orderInfo.calculated_quantity24
    && this.orderInfo[type3.toString()]  > this.orderInfo.calculated_quantity48) {
      // console.log('All 3 quantity is not available.')
    	this.toastr.error('Vestiging, 4 uur en 24 uur is meer toegevoegd dan beschikbaar.');
      return;
    }

    // Only Vest and 4uur is more 
    if (this.orderInfo[type1.toString()]  > this.orderInfo.calculated_inventory
    && this.orderInfo[type2.toString()]  > this.orderInfo.calculated_quantity24) {
      // console.log('vestiging and 4 uur quantity is not available.')
    	this.toastr.error('Vestiging en 4 uur is meer dan beschikbaar.');
      return;
    }

    // Only Vest and 24uur is more 
    if (this.orderInfo[type1.toString()]  > this.orderInfo.calculated_inventory
    && this.orderInfo[type3.toString()]  > this.orderInfo.calculated_quantity48) {
      // console.log('vestiging and 24 uur quantity is not available.')
    	this.toastr.error('Vestiging en 24 uur is meer dan beschikbaar.');
      return;
    }

    // Only 4uur and 24uur is more 
    if (this.orderInfo[type2.toString()]  > this.orderInfo.calculated_quantity24
    && this.orderInfo[type3.toString()]  > this.orderInfo.calculated_quantity48) {
      // console.log('4 uur and 24 uur quantity is not available.')
    	this.toastr.error('4 uur en 24 uur is meer dan beschikbaar.');
      return;
    }

    // Only Vest is more 
    if (this.orderInfo[type1.toString()]  > this.orderInfo.calculated_inventory) {
      // console.log('Vestiging quantity is not available.')
      this.toastr.error('Vestiging hoeveelheid is meer dan beschikbaar.');
      return;
    }

    // Only 4uur is more 
    if(this.orderInfo[type2.toString()]  > this.orderInfo.calculated_quantity24){
      // console.log('4 uur quantity is not available.')
      this.toastr.error('4 uur aantal is meer dan beschikbaar.');
      return;
    }

    // Only 24uur is more 
    if(this.orderInfo[type3.toString()]  > this.orderInfo.calculated_quantity48){
      // console.log('24 uur quantity is not available.')
      this.toastr.error('24 uur aantal is meer dan beschikbaar.');
      return;
    }
  }

    for (const cartItem of localData) {
      if(cartItem.id == this.productData.id){
        flag = false;
        cartItem.inventory += (this.orderInfo.price ? this.orderInfo.price : 0);
        cartItem.quantity24 += (this.orderInfo.i24 ? this.orderInfo.i24 : 0);
        cartItem.quantity48 += (this.orderInfo.i48 ? this.orderInfo.i48 : 0);
      }
    }
    const header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json'
    });
    if(!flag){
      const sneak = this._snackBar.open('Quantity updated in cart', '', {
        duration: 3000
      });
      this.toastr.success('Aantal bijgewerkt in winkelwagen', '');
      // this.idProductDetailsView = false;
      this.userService.activeTabIndex = 3;
    }
    if (flag) {
      const newItem = {
      ...this.productData,
        type1,
        quentity: 1,
        inventory: this.orderInfo.price == null ? 0 : this.orderInfo.price,
        quantity24: this.orderInfo.i24 == null ? 0 : this.orderInfo.i24,
        quantity48: this.orderInfo.i48 == null ? 0 : this.orderInfo.i48,
        reference: null
      };
      // console.log(newItem);
      localData.push(newItem);
      // this.idProductDetailsView = false;
      this.userService.activeTabIndex = 3;
      this.toastr.success('Product toegevoegd aan winkelwagen', '');

    }
    this.userService.getTotalCount();
    this.router.navigate(['account']);
    // console.log('3333');
    localStorage.setItem('cartValues', JSON.stringify(localData));
    this.orderInfo = {
      price: 0,
      i24: 0,
      i48: 0,
      calculated_inventory: 0,
      calculated_quantity24: 0,
      calculated_quantity48: 0
    };
    }
  ngOnInit(): void {
    // console.log('init');
    this.apiUrl = environment.url;
    // @ts-ignore
    this.userInfo = JSON.parse(localStorage.getItem('user'))
    this.userInfo.location = this.userInfo.Location_Code;
    this.getFilteredData();
    this.idProductDetailsView = false;
    const url = this.apiUrl + '/get-webshop-position-group';
    this.soortOptions2.push({
      value: 'Alle',
      title: 'Alle'
    });
    this.http.get(url)
      .subscribe(res => {
        // @ts-ignore
        // console.log(res);
        // @ts-ignore
        res.data.forEach((value, index) => {
          this.soortOptions2.push({
            value: value.Code,
            title: value.Description
          });
        });
      });

    const url2 = this.apiUrl + '/get-manufacturer-names';
    this.merkOptions2.push('Alle');
    this.http.post(url2, null)
      .subscribe(res => {
        // console.log(res);
        this.merkOptions = res;
        // this.merkOptions2 = this.merkOptions2.data;
        this.merkOptions.data.map((value: any) => {
          this.merkOptions2.push(value);
        })
      });
  }

  // tslint:disable-next-line:typedef
  toggleDropdown(flag: string) {
    setTimeout(() => {
      if (flag == 'zoomer') {
        this.zomerOpen = !this.zomerOpen;
      }
      if (flag == 'si') {
        this.siOpen = !this.siOpen;
      }
      if (flag == 'merk') {
        this.merkOpen = !this.merkOpen;
      }
      if (flag == 'soort') {
        this.soortOpen = !this.soortOpen;
      }
      if (flag == 'lisi') {
        this.lisiOpen = !this.lisiOpen;
      }
    }, 50);

  }

  // tslint:disable-next-line:typedef
  setValue(selector: any, value: any, e: any = null) {
    e.stopPropagation();
    this.isFilterApplied = true;
    if (selector == 'zoomer') {
      this.zoomerSelected = value;
    }
    if (selector == 'si') {
      this.siSelected = value;
    }
    if (selector == 'merk') {
      this.merkSelected = value;
    }
    if (selector == 'soort') {
      this.soortSelected = value;
    }
    // if (selector == 'lisi') {
    //   this.lisiSelected = value;
    // }
    this.getFilteredData();
  }
  // tslint:disable-next-line:typedef
  getFilteredData() {
    this.isLoadingData = true;
    const userInfo = JSON.parse(localStorage.getItem('user')!);
    const payload = {
      is_filtered: this.isFilterApplied ? 1 : 0,
      // li_si: this.lisiSelected == 'Alle' ? null : this.lisiSelected,
      li_si: this.lisiSelected,
      season: this.zoomerSelected == 'Alle' ? null : this.zoomerSelected,
      speedIndex: this.siSelected == 'Alle' ? null : this.siSelected,
      merk: this.merkSelected == 'Alle' ? null : this.merkSelected,
      soort: this.soortSelected == 'Alle' ? null : this.soortSelected,
      height: this.bandenmat2 ? this.bandenmat2 : null,
      width: this.bandenmat1 ? this.bandenmat1 : null,
      diameter: this.bandenmat3 ? this.bandenmat3 : null,
      itemNumber: null,
      search: this.zookenBanden,
      customer_group: userInfo.Customer_Price_Group,
      Location_code : userInfo.Location_Code
    };
    // console.log(payload);
    const url = this.apiUrl + '/get-webshop-items';
    this.http.post(url, payload)
      .subscribe((res: any) => {
      this.dataSource = new MatTableDataSource(res.data);
      this.isLoadingData = false;
      this.resultsLength = res.data.length;
      this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;
    });
  }



  handlePage(event:any){
    // console.log('event', event);
    this.currentStatus  = event.pageIndex;
    // console.log(this.dataSource);
  }
  // tslint:disable-next-line:typedef
  openProduct(data: any) {
    // console.log(data, 'product data');
    // console.log(this.resultsLength);
    this.idProductDetailsView = true;
    this.productData = data;
    this.orderInfo = {
      price: 0,
      i24: 0,
      i48: 0,
      calculated_inventory: data.calculated_inventory == null ? 0 : data.calculated_inventory,
      calculated_quantity24: data.calculated_quantity24 == null ? 0 : data.calculated_quantity24,
      calculated_quantity48: data.calculated_quantity48 == null ? 0 : data.calculated_quantity48
    };
  }
  backButton(){
    // this.paginator.pageIndex = this.currentStatus;

    this.productData = null;
    this.orderInfo = {
      price: 0,
      i24: 0,
      i48: 0,
      calculated_inventory: 0,
      calculated_quantity24: 0,
      calculated_quantity48: 0
    };
    this.idProductDetailsView = false;
    setTimeout( () => {
      // console.log(this.paginator);
      // this.sort._stateChanges.next();
      this.paginator.pageIndex = this.currentStatus;
      this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;
    }, 500);
  }
}
